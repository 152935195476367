.Cta {
  position: relative;
}

.Cta-logo {
  display: flex;
  width: $gutter-h * 2;
  height: $gutter-v;
}

.Cta-title {
  position: relative;
  height: $gutter-v * 3;
  display: block;
  color: $color-foreground-1;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, lg);
  letter-spacing: $letter-tightening-1;

  @include break(md) {
    font-size: map-get($font-size-secondary, xl);
  }
}

.Cta-title--borderBottom {

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: $gutter-h * 2;
    height: $gutter-v / 4;
    background-color: $color-highlight;
  }
}
