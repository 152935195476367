.Section {

}

.Section--bgLight {
  color: $color-foreground-2;
  background-color: $color-light;
  overflow: hidden;
}

.Document {
  .Section--bgLight {
    width: 100%;
    background-color: $color-background-2;
  }
}

.Section--bgLightGrey {
  background-color: $color-background-1;
  overflow: hidden;
}

.Section-bgDarker {
  color: $color-foreground-1;
  background-color: $color-background-3;
}

.Section--marginExtra {
  margin: ($gutter-v * 2) 0;

  @include break(md) {
    margin: ($gutter-v * 4) 0;
  }
}

.Section--paddingExtra {
  padding: ($gutter-v * 2) 0;

  @include break(md) {
    padding: ($gutter-v * 4) 0;
  }
}

.Section--narrow {
  margin: $gutter-v / 2;

  @include break(md) {
    margin: ($gutter-v * 2) ($gutter-h * 5);
  }
}

.Section--pt-sm {
  padding-top: $gutter-v / 2;
}

.Section--pt-md {
  padding-top: $gutter-v;
}

.Section--pt-lg {
  padding-top: $gutter-v * 2;
}

.Section--pb-sm {
  padding-bottom: $gutter-v / 2;
}

.Section--pb-md {
  padding-bottom: $gutter-v;
}

.Section--pb-nr {
  padding-bottom: 2rem;
}

.Section--pb-lg {
  padding-bottom: $gutter-v * 3;
}

.Section--textCenter {
  text-align: center;
}
