.DiscoverGrid {
  &-bg {
    position: absolute;
    top: $gutter-v;
    left: 0;
    width: 100%;
    background-image: url(cdn_image('/img/bg-discover.jpg'));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top -3rem center;
    padding-top: 24rem;
    z-index: -1;

    @include break(md) {
      padding-top: 36rem;
    }
  }

  &-title.TitleSection {
    padding: ($gutter-v * 1.5) 0;
    margin-bottom: $gutter-v * 4;

    @include break(md) {
      padding: ($gutter-v * 3.25) 0;
    }

    @include break(lg) {
      padding: ($gutter-v * 4) 0;
      margin-bottom: ($gutter-v * 1.75);
    }
  }

  &-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include break(lg) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
}
