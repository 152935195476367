.HallFame {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  &-fencer {
    width: 9rem;
    margin: $gutter-v $gutter-h;

    @include break(md) {
      width: 12rem;
      margin: $gutter-v $gutter-h*2;
    }
  }

  &-frame {
    width: 100%;
    margin-bottom: $gutter-v;
    background-color: $color-background-4;
    border-radius: $border-radius-circle;
    overflow: hidden;
  }

  &-image {
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-position: center top;
    opacity: .92;
  }

  &-name {
    font-family: $font-secondary;
    font-weight: 500;
    color: $color-foreground-2;
    font-size: map-get($font-size-secondary, xxs);
    text-align: center;
  }
}
