.AthleteBio {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: $gutter-h / 2;
  padding-right: $gutter-h / 2;

  @include break(sm) {
    padding-left: $gutter-h * 1.5;
    padding-right: $gutter-h * 1.5;
  }

  @include break(md) {
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: $gutter-h * 2;
    padding-right: $gutter-h * 2;
  }
}

.AthleteBio-heading {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  font-size: map-get($font-size-secondary, md);

  @include break(md) {
    flex-direction: row;
    margin-bottom: $gutter-v / 1.5;
    font-size: map-get($font-size-secondary, lg);
  }
}

.AthleteBio-body {
  flex-basis: 100%;
  margin-top: 0;
  margin-bottom: $gutter-v / 3;
  color: $color-foreground-2;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, xs);
  line-height: 1.4;

  @include break(md) {
    font-size: map-get($font-size-primary, sm);
  }
}

.AthleteBio-mainContentWrapper {
  flex-basis: 100%;
  margin-bottom: $gutter-v;

  @include break(md) {
    flex-basis: 66.66%;
    margin: ($gutter-v * 1.5) 0;
    padding-right: $gutter-h * 1.5;
  }
}

.AthleteBio-mainContent {
  margin-bottom: $gutter-v;
  padding: ($gutter-v) ($gutter-h);
  background-color: $color-light;
  box-shadow: $box-shadow-low;
  border-radius: $border-radius-1;

  @include break(sm) {
    margin-bottom: $gutter-v * 1.25;
  }

  @include break(md) {
    flex-basis: 100%;
  }
}

.AthleteBio-events,
.AthleteBio-photoGallery,
.AthleteBio-videos {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  @include break(md) {
    flex-direction: row;
    width: 100%;
  }

  .GridCard {
    display: none;

    &:first-child {
      display: flex;
      margin-left: 0;
    }

    @include break(md) {
      display: flex;
      flex-basis: 30%;
    }
  }
}

.AthleteBio-events {
  flex-direction: column;
  align-items: center;

  @include break(md) {
    justify-content: flex-start;
  }

  .Table {
    width: 100%;
  }

  .Table-cell {
    color: $color-foreground-3;
    font-size: map-get($font-size-secondary, xxs);
    text-transform: capitalize;
  }
}

.AthleteBio-medalsWrapper {
  overflow: auto;
  -ms-overflow-style: none;
}

.AthleteBio-medalsTable {
  width: 100%;
}

.AthleteBio-medalsTableHeaderCell {
  font-family: 'futurabold';
  font-size: 14px;
  background: #182d70;
  color: #fff;
  border: none;
  text-align: center;
  padding: 10px;
}

.AthleteBio-medalsTableHeaderCell:first-child {
  border-top-left-radius: 5px;
}

.AthleteBio-medalsTableHeaderCell:last-child {
  border-top-right-radius: 5px;
}

.AthleteBio-medalsTableCell {
  font-family: 'futurapt';
  font-size: 14px;
  background: #fff;
  color: #182d70;
  border: none;
  text-align: center;
  border: 1px solid #efefef;
  padding: 8px;
  line-height: 1.4;
}

.AthleteBio-medalsTableCell--counter {
  cursor: pointer;
}

.AthleteBio-medalsTableCell--odd {
  background: #efefef;
}

.AthleteBio-medalsTableCell--detail {
  background: #dedbdb;
  vertical-align: top;
}

@media (max-width: 768px){
  .AthleteBio-medalsTableCell--md {
    display: none;
  }
}

.AthleteBio-medalsTableRow--detail {
  display: none;
}

.AthleteBio-medalsTableRow--detailActive {
  display: table-row;
}

.AthleteBio-medalsTableRow {
  .AthleteBio-medalsArrowDown {
    display: inline;
    line-height: 1;
    position: relative;
    top: -2px;
  }

  .AthleteBio-medalsArrowUp {
    display: none;
    line-height: 1;
  }
}

.AthleteBio-medalsTableCell--arrow {
  vertical-align: middle;
  width: 5%;
}

.AthleteBio-medalsTableCell--competition {
  width: 5%;
}

.AthleteBio-medalsTableCell--medal {
  width: 23%;
}

.AthleteBio-medalsTableCell--total {
  width: 9%;
}

.AthleteBio-medalsTableRow--titleActive {
  .AthleteBio-medalsArrowDown {
    display: none;
    line-height: 1;
  }

  .AthleteBio-medalsArrowUp {
    display: inline;
    line-height: 1;
    position: relative;
    bottom: -2px;
  }
}

.AthleteBio-medalIcon {
  display: inline-block;
  height: 22px;
  width: 22px;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  margin-left: 3px;
  background-image: url(../img/medals.png);
}

.AthleteBio-medalIcon--gold {
  background-position: 0 -78px;
  background-repeat: no-repeat;
}

.AthleteBio-medalIcon--gold {
  background-position: 0 -78px;
  background-repeat: no-repeat;
}

.AthleteBio-medalIcon--silver {
  background-position: 0 -278px;
  background-repeat: no-repeat;
}

.AthleteBio-medalIcon--bronze {
  background-position: 0 -178px;
  background-repeat: no-repeat;
}

.AthleteBio-medalItem {
  flex-basis: 49%;
  max-width: 12rem;
  margin-bottom: $gutter-v / 3;
  margin-right: $gutter-h / 4;
  padding: ($gutter-v / 3) 0;
  border-radius: $border-radius-1;
  color: $color-foreground-3;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, 3xs);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: $letter-spacing-2;

  @include break(md) {
    flex-basis: 33.33%;
  }

  @include break(xxl) {
    flex-basis: 20%;
  }
}

.AthleteBio-medalItem--gold {
  background-color: $color-gold;
}

.AthleteBio-medalItem--silver {
  background-color: $color-silver;
}

.AthleteBio-medalItem--bronze {
  background-color: $color-bronze;
}

.AthleteBio-label {
  color: $color-foreground-1;
  font-weight: 500;
}

.AthleteBio-filter {
  display: flex;
  flex-direction: row;
  font-size: map-get($font-size-secondary, 3xs);
}

.AthleteBio-sidebar {
  flex-basis: 100%;
  margin: $gutter-v 0;

  @include break(md) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 33.33%;
    margin: ($gutter-v * 1.5) 0;
  }
}

.AthleteBio-sidebarHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .Form-selectBox {
    background-color: rgba($color-light, .8);
  }

  .Form-selectOption--icon {

    span {
      flex-basis: 100%;
    }
  }
}

.AthleteBio-sidebar .Tabs {
  margin-top: $gutter-v;
}

.AthleteBio-sidebar .Table-title {
  padding: $gutter-v / 2;
  color: $color-foreground-1;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, sm);
}

.AthleteBio-notFound {
  padding: $gutter-h $gutter-v;
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, 3xs);
  text-align: center;
}
