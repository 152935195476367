.MediaAndSponsorsSocial {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 54rem;
  margin: 0 auto;

  @include break(md) {
    justify-content: space-between;
    max-width: 100%;
  }

  &-title {
    flex-basis: 100%;

    @include break(md) {
      padding: $gutter-v/2 0;
    }
  }

  &-col {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xs);
    color: $color-foreground-3;
    margin-top: $gutter-v;
    margin-bottom: $gutter-v;
    margin-right: $gutter-h*2;

    @include break(md) {
      margin-top: $gutter-v*1.5;
      margin-bottom: $gutter-v*1.5;
    }
  }

  &-logo {
    display: block;
    max-width: 2rem;
    margin-right: $gutter-h/1.5;
  }
}
