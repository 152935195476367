.ProfileInfo-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #182d70;
  @include break(lg) {
    background: none;
  }
}

.ProfileInfo-transparency {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: $gutter-h * 4;
  height: 100%;
  background: linear-gradient(to right, rgba($color-background-5, 0) 0%, $color-background-5 100%);
  pointer-events: none;
  opacity: .75;
  z-index: 2;

  @include break(md) {
    display: none;
  }
}

.ProfileInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: $gutter-v / 1.25;
  padding-bottom: $gutter-v / 1.25;
  padding-left: 0;
  padding-right: 0;
  color: $color-light;
  font-family: 'futurapt';
  font-size: map-get($font-size-secondary, xxs);
  overflow-x: auto;
  overflow-y: hidden;

  @include break(md) {
    padding-top: $gutter-v;
    padding-bottom: $gutter-v;
    font-size: map-get($font-size-secondary, sm);
  }

  &-divider {
    min-width: 1px;
    margin: 0 ($gutter-h * 1.25);
    padding-top: $gutter-v;
    background-color: $color-background-1;

    &:last-of-type {
      @include break(md) {
        display: none;
      }
    }
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem 0;
    text-transform: capitalize;
    span:not(.ProfileInfo-label){
      font-family: 'futurabold';
    }

    flex: 1 1 0;
    background: #182d70;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &--show-md {
      display: none;

      @include break(md) {
        display: flex;
      }
    }
  }

  &-link {
    padding-right: $gutter-h / 3;
    color: #fff;

    &:hover,
    &:active,
    &:visited {
      color: $color-light;
    }
  }

  &-label {
    padding-right: $gutter-h / 3;
    color: #fff;

    &--highlighted {
      color: $color-light;
    }
  }

  &-rank {
    position: relative;
    display: block;
    margin-left: $gutter-h * 1.25;
    margin-right: $gutter-h / 1.5;
    padding: 0 2px;

    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
      background-repeat: no-repeat;
      background-size: auto 100%;

    }

    &::before {
      left: -1.5rem;
      background-image: url(cdn_image('/img/icon-wreath-left-blue.svg'));
      background-position: left top;
    }

    &::after {
      right: -1.5rem;
      background-image: url(cdn_image('/img/icon-wreath-right-blue.svg'));
      background-position: right top;
    }
  }

  &-weaponDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: $gutter-h * 4;
    background-color: transparent;
    background-image: url(cdn_image('/img/icon-drop.svg'));
    background-position: right ($gutter-h / 4) center;
    background-size: 1.2rem auto;
    background-repeat: no-repeat;
    color: $color-light;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xxs);
    text-transform: capitalize;
    cursor: pointer;
    appearance: none;
    border-radius: 0;
    border: none;
    box-shadow: none;

    &:focus {
      opacity: 1;
      outline: none;
    }

    @include break(md) {
      font-size: map-get($font-size-secondary, xs);
    }
  }
}

.ProfileInfo-value {
  white-space: nowrap;
}

.ProfileInfo-item .fencerCategory{
  padding: 0;
  background-color: #182d70;
}
