.WfdExcerpt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 54rem;
  margin: 0 auto;

  @include break(md) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
  }

  &-info {
    margin-bottom: $gutter-v*2;
    color: $color-foreground-2;
    font-size: map-get($font-size-primary, sm);
    line-height: 1.4;

    @include break(md) {
      flex-basis: 66.66%;
      margin-bottom: 0;
      padding-right: $gutter-h*3;
    }
  }

  &-img {
    display: block;
    max-width: 30rem;

    @include break(md) {
      flex-basis: 33.33%;
      margin-top: $gutter-v*4;
    }
  }
}

.WfdButtons {
  margin-bottom: $gutter-v*2;
  text-align: center;
}
