.Featured {
  padding-top: 0;
  padding-bottom: $gutter-v*2;

  @include break(md) {
    padding-top: $gutter-v;
    padding-bottom: $gutter-v*3;
  }
}

.Featured-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $gutter-v*1.5;
  padding: 0;

  @include break(md) {
    margin-bottom: $gutter-v*2;
    padding-left: $gutter-h*2;
    padding-right: $gutter-h*2;
  }
}

.Featured-header-title {
  margin: 0 auto;
  font-size: map-get($font-size-secondary, lg);
  text-align: center;

  @include break(md) {
    margin: 0;
    font-size: map-get($font-size-secondary, xl);
    text-align: left;

    &::after {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
