.TopFencers {
  padding: $gutter-v $gutter-h;

  .MoreLabel {
    margin-top: $gutter-v;
  }

  @include break(md) {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-right: -5px;
    box-shadow: none;
    border-right: $border-thin;
  }
}

.TopFencers-content {
  display: block;
  font-family: $font-secondary;
}

.TopFencers-category {
  position: relative;
  width: 100%;
}

.TopFencers-label {
  padding-right: 1rem;
  background-color: $color-light;
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, 3xs);
  z-index: 2;

  @include break(md) {
    font-size: map-get($font-size-secondary, xxs);
  }
}

.TopFencers-athlete {
  display: inline-flex;
  align-items: center;
  margin: ($gutter-v / 4) 0;
  padding: $gutter-v / 2;
  width: 49%;
  border-radius: $border-radius-1;
  cursor: pointer;

  @include break(md) {
    &.active {

      .TopFencers-athlete-photo {
        background-color: $color-light;
      }
    }
  }

  .Table-country {
    background-color: transparentize($color-background-5, 0.9)
  }
}


.TopFencers-athlete-frame {
  width: 50px;
}

.TopFencers-athlete-photo {
  padding-top: 100%;
  background-color: $color-background-2;
  background-size: cover;
  background-position: center top;
  border-radius: $border-radius-circle;
}

.TopFencers-athlete-info {
  width: calc(100% - 50px);
  padding-left: 2rem;
}

.TopFencers-athlete-name {
  margin-top: 0;
  margin-bottom: $gutter-v / 2.5;
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, xxs);
  line-height: 1.1;
  text-transform: capitalize;

  @include break(md) {
    font-size: map-get($font-size-secondary, xs);
  }

  @include break(lg) {
    font-size: map-get($font-size-secondary, sm);
  }
}

.TopFencers-athlete-country {
  margin-top: 0;
  margin-bottom: 0;
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, 3xs);
  line-height: 1;

  span {
    margin: 0 .3rem;
  }

  @include break(md) {
    font-size: map-get($font-size-secondary, xxs);
  }
}
