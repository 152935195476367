.Subscribe {
  text-align: center;
  padding-bottom: $gutter-v*3;

  @include break(md) {
    padding-top: $gutter-v*3;
  }

  &-container {
    width: 100%;
    max-width: 64rem;
    .Subscribe-inputText,
    .Subscribe-dropdown {
      display: block;
      width: 100%;
      padding: $gutter-v / 2;
      margin-bottom: $gutter-v/1.5;
      height: 5.6rem;
      background-color: $color-light;
      font-size: map-get($font-size-secondary, md);
      font-weight: 500;
      appearance: none;
      border: none;
      border-radius: 0;
      transition: background-color .2s;

      &::placeholder {
        color: $color-foreground-3;
      }

      &:focus {
        outline: none;
        color: $color-foreground-1;
        background-color: $color-background-1;
      }
    }
  }

  &-inputText {
    color: $color-foreground-1;
  }

  &-dropdown {
    color: $color-foreground-3;
    margin-bottom: $gutter-v*1.5;
    padding-right: $gutter-h*2.5;
    background-image: url(cdn_image('/img/icon-drop.svg'));
    background-position: right ($gutter-h / 1.25) center;
    background-size: 1.2rem auto;
    background-repeat: no-repeat;

    &:focus {
      outline: none;
      color: $color-foreground-1;
    }
  }

  &-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $gutter-v;
  }

  &-checkbox {
    position: relative;
    display: flex;
    flex-basis: 100%;
    align-items: center;
    padding: $gutter-v $gutter-h/1.5;
    margin-bottom: $gutter-h/2;
    background-color: $color-background-3;
    border-radius: $border-radius-1/2;

    @include break(sm) {
      flex-basis: 49%;

      &--full-row {
        flex-basis: 100%;
      }
    }

    &-icon {
      height: $gutter-v;
      width: $gutter-h;
      pointer-events: none;
      border-radius: $border-radius-1;
      border: 1px solid #adadac;
      position: relative;
      &:after {
        content: '';
        background: url(../img/icons/check2.png) no-repeat center;
        background-size: 12px 9px;
        background-color: #fff;
        border-radius: 5px;
        transform: scale(0);
        transition: 200ms;
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
      }
    }

    input {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;

      &:checked ~ .Subscribe-checkbox-icon {
        &:after {
          transform: scale(1);
        }
        border: 1px solid #182d70;
      }

      &:checked ~ .Subscribe-checkbox-label,
      &:checked ~ .Subscribe-radio-label {
        color: $color-foreground-1;
      }
    }

    &-label {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: $gutter-h/2;
      color:  #adadac;
      line-height: 1;
      cursor: pointer;
      font-weight: 100;
      font-size: 17px !important;

      @include break(lg) {
        font-size: map_get($font-size-secondary, xs);
      }
    }
  }

  &-note {
    color: $color-foreground-4;
    font-size: map-get($font-size-secondary, xxs);
    font-weight: 500;
  }
}

.Subscribe-validation {
  color: $color-error;
  text-align: left;
  padding: 0;
  font-size: map-get($font-size-secondary, xs);

  li {
    margin-bottom: $gutter-v/4;
  }
}
