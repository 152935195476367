.TitleSection {
  margin-top: 0;
  margin-bottom: $gutter-v * 1.25;
  color: $color-light;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xl);
  text-align: center;

  @include break(md) {
    font-size: map-get($font-size-secondary, xxl);
    letter-spacing: $letter-tightening-1;
  }

  &--alignLeft {
    text-align: left;
  }

  &--smaller {
    font-size: map-get($font-size-secondary, lg);

    @include break(md) {
      font-size: map-get($font-size-secondary, xl);
    }
  }

  &--link {
    display: block;
    margin: ($gutter-v / 3) auto;
  }

  &--mb {
    @include break(md) {
      margin-bottom: $gutter-v * 2.5;
    }
  }

  &--dark {
    color: $color-foreground-1;
  }

  &--border {
    &::after {
      content: '';
      position: relative;
      display: block;
      margin-top: $gutter-v / 1.5;
      margin-left: auto;
      margin-right: auto;
      max-width: $gutter-h * 3;
      height: $gutter-v / 4;
      background-color: $color-highlight;
    }

    @include break(md) {
      &::after {
        margin-top: $gutter-v;
        max-width: $gutter-h * 4;
      }
    }
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--block {
    font-size: 2.6rem;
    line-height: 1;
    padding: 3.7rem;
    border: 1px solid #a7a7a7;
    box-shadow: 3px 2px 12px #c7c7ce;
    border-radius:5px;
    background-size: cover;
    margin: 0 !important;
  }

  &--block-primary {
    background: #182d70 url(cdn_image('/img/BCK1.jpg')) 50% 30%;
  }
  &--block-secondary {
    color: #182d70;
    background: #d0d1d3 url(cdn_image('/img/BCK2.jpg')) 50% 30%;
  }
}
