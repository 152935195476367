.ProfilePics {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  a.ProfilePics-image {
    display: flex;
    justify-content: flex-end;
    width: 5rem;
    height: 5rem;
    margin: 1rem;
    background-color: $color-background-2;
    background-size: cover;
    background-position: center top;
    border-radius: $border-radius-circle;

    @include break(md) {
      width: 6rem;
      height: 6rem;
    }
  }
}
