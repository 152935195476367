$mapMinHeight: 75vh;

.FindClub {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  overflow: hidden;

  @include break(md) {
    margin-top: $gutter-v*2;
  }
}

.FindClub-mapWrapper {
  position: relative;
  width: 100%;
  min-height: $mapMinHeight/2;
  z-index: 0;

  @include break(md) {
    min-height: $mapMinHeight;
  }
}

.FindClub-results {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: $mapMinHeight;
  border-radius: $border-radius-1;
  z-index: 1;

  @include break(md) {
    position: absolute;
    top: 6rem;
    bottom: 0;
    left: 2rem;
    width: 32rem;
    height: 80%;
  }

  &-dropdown {
    display: flex;
    height: 8rem;
  }

  &-container {
    display: block;
    height: 100%;
    color: $color-foreground-1;
    font-family: $font-primary;
    font-size: map-get($font-size-primary, xxs);
    background-color: $color-light;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    overflow-y: scroll;
  }

  &-content {

    .ResultItem:nth-child(odd) {
      background-color: $color-background-1;
    }
  }

  &-log {
    padding: $gutter-v $gutter-h;
    font-size: map-get($font-size-primary, xs);
    text-align: center;
  }
}
