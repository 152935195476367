.SvgIcon {
  fill: $color-foreground-1;
  transition: fill .2s;

  &--highlight {
    fill: $color-highlight;
  }

  &--light {
    fill: $color-light;
  }

  &--hoverHighlight {
    &:hover {
      fill: $color-highlight;
    }
  }
}
