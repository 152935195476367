.SecondaryMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: $gutter-v;
  background-color: $color-light;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xs);
  box-shadow: $box-shadow-even;

  @include break(md) {
    display: none;
  }

  &-item {
    width: 100%;
    padding: $gutter-v 0 ($gutter-v / 1.25);
    color: $color-foreground-3;
    text-align: center;
    cursor: pointer;

    &.is-active {
      color: $color-foreground-1;
      border-bottom: 3px solid $color-highlight;
    }
  }

  &-link {
    pointer-events: none;

    &--handle-events {
      pointer-events: auto;
    }
  }
}

.SecondaryMenu-block {
  display: none;
  width: 100%;

  &.is-active {
    display: block;
  }

  @include break(md) {
    display: block;
  }
}
