.Gallery {
  &-item {
    display: flex;
    flex-direction: column;
    padding: $gutter-v/2 $gutter-h/2;
  }

  &-card {
    flex: 1 1 auto;
    padding: $gutter-v/2 $gutter-h/2;
    font-family: $font-secondary;
    font-size: map-get($font-size-primary, xs);
    background-color: $color-light;
    border-radius: $border-radius-2;
    box-shadow: $box-shadow-low;
    transition: transform .2s, box-shadow .2s;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      box-shadow: $box-shadow-soft;
      transform: scale(1.015);
    }
  }

  &-image {
    width: 100%;
    padding-top: 60%;
    background-color: $color-foreground-3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    pointer-events: none;
  }
  &-label {
    margin: $gutter-v/2;
    text-align: center;
    color: $color-foreground-3;
    pointer-events: none;
  }
}
