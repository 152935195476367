a {
  color: $color-foreground-1;
  text-decoration: none;
}

a:active,
a:hover,
a:focus {
  color: $color-foreground-2;
}

strong,
b {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

.Text--show-sm {
  display: none;

  @include break(sm) {
    display: block;
  }
}

.Text--hide-md {
  display: block;

  @include break(md) {
    display: none;
  }
}

.Text--show-md {
  display: none;

  @include break(md) {
    display: block;
  }
}

.Text--show-lg {
  display: none;

  @include break(lg) {
    display: block;
  }
}
