.AccordionMobile {
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xxs);

  &-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:first-child {
      border-top: 1px solid $color-foreground-6;
    }
  }

  &-flagWrapper {
    display: inline;
    margin-right: $gutter-v/6;
    padding: $gutter-v/8 $gutter-h/6;
    background-color: $color-background-2;
    border-radius: $border-radius-2*10;
  }

  &-nationality,
  &-name {
    margin-right: $gutter-h / 2;
  }

  &-name--ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-heading {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: $gutter-v $gutter-h;
    border-bottom: 1px solid $color-foreground-6;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      right: $gutter-h / 2;
      background-image: url(cdn_image('/img/icon-plus.svg'));
      background-repeat: no-repeat;
      background-size: auto ($gutter-v / 2);
      background-position: center right;
    }
  }

  &-heading--selected {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 1rem;
      top: 0;
      bottom: 0;
      right: $gutter-h / 2;
      background-image: url(cdn_image('/img/icon-minus.svg'));
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
    }
  }

  &-content {
    display: none;
    font-weight: 300;
  }

  &-content--expand {
    display: block;
  }

  .Table {
    padding: 0;
  }

  .Table-head,
  .Table-row {
    background-color: $color-background-1;
  }

  .Table-row--stripes {

    &:nth-child(odd) {
      background-color: $color-background-2;
    }
  }

  .Table-cell {
    padding: $gutter-v 0;
  }

  .Form-dropdown {
    margin-top: $gutter-v / 2;
  }
}
