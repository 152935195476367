.Overview {
  display: flex;
  flex-direction: column;
  font-family: 'futurabold';
  font-size: map-get($font-size-secondary, md);
  font-weight: 600;
  .Overview-label{
    font-family: 'futurapt';
    font-weight: 100;
    line-height: 1;
  }
  .Overview-group{
    font-size: 0.8em;
    margin-bottom: 4rem;
    .Table-country{
      padding: 0;
      background:#fff;
    }
    @include break(md) {
      font-size: 1.3em;
    }
  }
  .Overview-section{
    .CompetitionContent-button{
      padding: 0.5em 1.5em;
      font-family: 'futurapt';
      font-weight: 100;
      text-align: center;
      background:#fff;
      box-shadow: 0 2px 11px #d9d9d9;
      &:after{
        opacity: 1;
      }
    }
  }
  
}

.Overview-section {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}

.Overview-group {
  margin-bottom: $gutter-v;
}

.Overview-country {
  display: flex;
  flex-direction: row;

  &-name {
    text-transform: capitalize;
  }

  &-flag {
    margin-right: $gutter-h/2;
  }  
}

.Overview-label {
  margin-bottom: $gutter-v/4;
  color: $color-foreground-3;

  &--dark {
    color: $color-foreground-1;
  }
}
