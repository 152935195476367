.MediaAndSponsorsPresentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;

  @include break(md) {
    flex-direction: row;
    margin: $gutter-v/3 0;
    text-align: left;
  }

  &-category {
    margin-top: 0;
    margin-bottom: $gutter-v;
    color: $color-foreground-1;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, lg);

    @include break(md) {
      color: $color-foreground-3;
    }
  }

  &-info {
    width: 100%;
    max-width: 54rem;
    margin: 0 auto $gutter-v;
    color: $color-foreground-2;
    font-size: map-get($font-size-primary, sm);
    line-height: 1.4;

    @include break(md) {
      width: 66.66%;
      max-width: 64rem;
      margin-bottom: $gutter-v/2;
      margin-left: 0;
    }

    &--full {
      width: 100%;
      max-width: 100%;
    }
  }

  &-impact {
    margin-bottom: $gutter-v;
    color: $color-foreground-1;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xxl);
    letter-spacing: $letter-tightening-2;

    @include break(md) {
      font-size: map-get($font-size-secondary, 3xl);
    }
  }

  &-underline {
    position: relative;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      bottom: 1.1rem;
      left: 0;
      width: 100%;
      height: .6rem;
      background-color: $color-highlight;
      z-index: -1;
    }

    @include break(md) {
      &::after {
        bottom: 1.3rem;
        height: .75rem;
      }
    }
  }

  &-infograph {
    max-width: 15rem;

    &-label {
      text-align: center;
      text-transform: uppercase;
      font-family: $font-secondary;
      padding-bottom: $gutter-h/2;
    }

    img {
      margin-top: $gutter-h/2;
    }
  }

  &-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, md);
    color: $color-foreground-1;

    @include break(md) {
      justify-content: flex-start;
      flex-wrap: nowrap;
      margin-top: $gutter-v*1.5;
    }
  }

  &-dataCol {
    margin-bottom: $gutter-v;
    margin-right:  $gutter-h*2;

    @include break(md) {
      margin-bottom: 0;
    }
  }

  &-label {
    font-size: map-get($font-size-secondary, sm);
    color: $color-foreground-3;
  }
}
