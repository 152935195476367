$gutter-h--xs: $gutter-h / 4;
$gutter-h--md: $gutter-h / 2;

.Historial {
  color: $color-foreground-2;
  font-size: map-get($font-size-secondary, xs);

  .Grid {
    width: 100%;
  }

  .Table-head,
  .Table-row {
    margin: 0 $gutter-h--xs;
  }

  .Table-cell {
    padding: $gutter-v $gutter-h--md;
  }
}

.Historial-link {
  display: inline-flex;
  align-items:center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  &::after {
    content: '';
    width: $gutter-h--md;
    height: $gutter-h--md;
    margin-right: $gutter-h--xs;
    background-image: url(cdn_image('/img/icon-play.svg'));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.Historial-label {
  text-transform: capitalize;
}

a.Historial-label {
  display: block;
}

.Historial-label--pointer {
  cursor: pointer;
  text-decoration: underline;
}

.Historial-label--colorDark {
  color: $color-foreground-1;
}

.Historial-label--colorMed {
  color: $color-foreground-2;
}

.Historial-label--colorLight {
  color: $color-foreground-4;
}

.Historial-date {
  letter-spacing: $letter-spacing-1 / 2;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include break(md) {
    max-width: 20rem;
  }
}

.Historial-labelWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}
