html {
  font-size: 10px
}

body {
  margin: 0;
  color: $color-foreground-5;
  font-family: $font-primary;
  font-size: 1.8rem;
  background-color: $color-background-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
