.Button {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: ($gutter-v / 1.25) ($gutter-h / 1.25);
  border: 0;
  background-color: $color-background-2;
  color: $color-foreground-2;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, sm);
  user-select: none;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border-radius: $border-radius-1;
  transition: color .3s, background .3s;

  &:hover, &.is-active {
    color: $color-foreground-1;
  }

  &.is-active {
    text-decoration: underline;
  }

  @include break(md) {
    padding: $gutter-v $gutter-h;
  }

  @include break(lg) {
    font-size: map-get($font-size-secondary, xs);
  }

  &--margins {
    margin: $gutter-v/3 $gutter-h/3;
  }

  &--noMargin {
    margin: 0;
  }

  &--wide {
    display: block;
    width: 100%;
    max-width: 64rem;
  }

  &--overlay {
    background-color: $color-highlight;
    color: $color-foreground-1;
    border-radius: 0;
  }

  &--dark {
    background: #182d70;
    color: white;
    &:hover {
      color: $color-action-1;
    }
  }

  &--background3 {
    background-color: $color-background-3;
    color: $color-foreground-1;
  }

  &--tableauPdf {
    &:first-child:not(:only-child) {
      margin-right: $gutter-h;
    }
  }

  &--bigger {
    font-size: map-get($font-size-secondary, xs);

    @include break(lg) {
      font-size: map-get($font-size-secondary, sm);
    }
  }

  &--smaller {
    padding: ($gutter-v / 1.5) ($gutter-h / 1.5);

    @include break(md) {
      padding: ($gutter-v / 1.25) ($gutter-h / 1.25);
    }
  }

  &--small-pt {
    padding-top: $gutter-v/2;
  }

  &--small-pb {
    padding-bottom: $gutter-v/2;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--blue {
    background-color: $color-action-1;
    color: $color-foreground-1;

    &:hover {
      box-shadow: $box-shadow-even;
    }
  }

  &--underline {
    display: block;
    width: 100%;
    font-size: map-get($font-size-secondary, sm);
    background-color: transparent;
    border-bottom: .3rem solid $color-foreground-6;

    &::before {
      content: '';
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      right: 0;
      width: 100%;
      height: .3rem;
      background-color: $color-foreground-4;
      transform: scaleY(0);
      transition: transform .3s;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      right: $gutter-h * 1.25;
      padding-top: $gutter-v / 1.75;
      background-image: url(cdn_image('/img/icon-go.svg'));
      background-repeat: no-repeat;
      background-size: auto ($gutter-v / 1.75);
      background-position: center right;
      opacity: .5;
      transition: opacity .3s, transform .3s;
    }

    &:hover {
      &::before {
        transform: scaleY(1);
      }

      &::after {
        opacity: 1;
        transform: translateX(2px);
      }
    }

    @include break(md) {
      font-size: map-get($font-size-secondary, md);
    }
  }

  &--link {
    padding-top: $gutter-v / 2;
    background-color: transparent;
    font-family: $font-primary;
    font-size: map-get($font-size-primary, xs);

    @include break(md) {
      padding-top: $gutter-v / 2;
    }
  }

  &--expand {
    position: relative;
    display: block;
    width: 100%;
    padding: 3rem 0;
    background-color: $color-background-1;
    border-radius: 0 0 .4rem .4rem;

    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-image: url(cdn_image('/img/icon-drop.svg'));
      background-repeat: no-repeat;
      background-size: auto ($gutter-v / 1.75);
      background-position: center center;
      opacity: .5;
    }

    &-up::after {
      background-image: url(cdn_image('/img/icon-drop.svg'));
      transform: rotate(180deg);
    }

    &-blue::after {
      background-image: url(cdn_image('/img/icon-drop-blue.svg'));
    }
  }

  &--radiusBotton {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
  }

  &--icon {
    padding-right: $gutter-h * 3;
    text-align: left;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: $gutter-h * 1.25;
      bottom: 0;
      left: 0;
      padding-top: $gutter-v / 1.75;
      background-repeat: no-repeat;
      background-size: auto ($gutter-v / 1.75);
      background-position: center right;
      opacity: .5;
      transition: opacity .3s, transform .3s;
    }

    &:hover {
      &::after {
        opacity: 1;
        transform: translateX(2px);
      }
    }

    @include break(md) {
      padding-right: $gutter-h * 3.5;
    }
  }

  &--icon-bell::after {
    background-image: url(cdn_image('/img/icon-bell.svg'));
  }

  &--icon-clock::after {
    background-image: url(cdn_image('/img/icon-clock.svg'));
  }

  &--icon-arrow::after {
    background-image: url(cdn_image('/img/icon-arrow.svg'));
  }

  &--icon-article::after {
    background-image: url(cdn_image('/img/icon-article.svg'));
  }

  &--icon-camera::after {
    background-image: url(cdn_image('/img/icon-camera.svg'));
  }

  &--icon-clock::after {
    background-image: url(cdn_image('/img/icon-clock.svg'));
  }

  &--icon-download::after {
    background-image: url(cdn_image('/img/icon-download.svg'));
  }

  &--icon-download-dark::after {
    background-image: url(cdn_image('/img/icon-download-dark.svg'));
  }

  &--icon-download-overlay::after {
    background-image: url(cdn_image('/img/icon-download-overlay.svg'));
  }

  &--icon-drop::after {
    background-image: url(cdn_image('/img/icon-drop.svg'));
  }

  &--icon-edit::after {
    background-image: url(cdn_image('/img/icon-edit.svg'));
  }

  &--icon-external::after {
    background-image: url(cdn_image('/img/icon-external.svg'));
  }

  &--icon-facebook::after {
    background-image: url(cdn_image('/img/icon-facebook.svg'));
  }

  &--icon-full::after {
    background-image: url(cdn_image('/img/icon-full.svg'));
  }

  &--icon-go::after {
    background-image: url(cdn_image('/img/icon-go.svg'));
  }

  &--icon-instagram::after {
    background-image: url(cdn_image('/img/icon-instagram.svg'));
  }

  &--icon-lightning::after {
    padding-top: ($gutter-v / 1.15);
    background-image: url(cdn_image('/img/icon-lightning-blue.svg'));
    background-size: auto ($gutter-v / 1.15);
  }

  &--icon-minus::after {
    background-image: url(cdn_image('/img/icon-minus.svg'));
  }

  &--icon-play::after {
    background-image: url(cdn_image('/img/icon-play-dark.svg'));
  }

  &--icon-plus::after {
    background-image: url(cdn_image('/img/icon-plus-blue.svg'));
  }

  &--icon-ticket::after {
    background-image: url(cdn_image('/img/icon-ticket.svg'));
  }

  &--icon-twitter::after {
    background-image: url(cdn_image('/img/icon-twitter.svg'));
  }
}
