.Banner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  padding: ($gutter-v * 3) 0;
  overflow: hidden;

  @include break(md) {
    padding: ($gutter-v * 4) 0;
  }

  @include break(lg) {
    padding: ($gutter-v * 4.5) 0;
  }

  &--link {
    &:hover {
      .Banner-arrow {
        transform: translateX(1rem);
      }

      .Banner-bg {
        transform: scale(1.05);
      }
    }
  }

  &.Margin-bottom {
    margin-bottom: $gutter-v * 1.5;

    @include break(sm) {
      margin-bottom: $gutter-v * 2;
    }

    @include break(md) {
      margin-bottom: $gutter-v * 3;
    }
  }

  &.Margin-top {
    margin-top: $gutter-v * 1.5;

    @include break(sm) {
      margin-top: $gutter-v * 2;
    }

    @include break(md) {
      margin-top: $gutter-v * 3;
    }
  }
}

.Banner-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color-background-4;
  background-size: cover;
  background-position: center;
  z-index: -1;
  transition: transform .3s;
}

.Banner-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.Banner-label {
  margin-top: 0;
  margin-bottom: $gutter-v;
  color: $color-light;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xs);
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;

  @include break(lg) {
    font-size: map-get($font-size-secondary, sm);
  }
}

.Banner-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 42rem;
  margin: 0 auto;

  @include break(md) {
    flex-wrap: nowrap;
  }
}

.Banner-form-dropdown {
  background-image: url(cdn_image('/img/icon-drop.svg'));
  background-position: right ($gutter-h / 1.25) center;
  background-size: 1.2rem auto;
  background-repeat: no-repeat;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  border: none;
}

.Banner-form-dropdown,
input[type=text].Banner-form-text,
input[type=email].Banner-form-email {
  display: block;
  width: 100%;
  height: auto;
  padding-top: $gutter-v;
  padding-bottom: $gutter-v;
  padding-right: $gutter-h * 2;
  padding-left: $gutter-h;
  background-color: $color-background-4;
  color: $color-light;
  opacity: .66;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, sm);
  letter-spacing: $letter-spacing-1;
  line-height: 1;
  box-shadow: none;

  &::placeholder {
    color: $color-foreground-5;
  }

  &:focus {
    opacity: 1;
    outline: none;
  }

  @include break(md) {
    padding-top: $gutter-v*1.25;
    padding-bottom: $gutter-v*1.25;
  }

  @include break(lg) {
    font-size: map-get($font-size-primary, md);
  }
}

input[type=email].Banner-form-email {
  padding-left: $gutter-h * 2.5;
  background-image: url(cdn_image('/img/icon-email.svg'));
  background-position: left $gutter-h/1.25 center;
  background-repeat: no-repeat;
}

input[type=button].Banner-form-button,
input[type=submit].Banner-form-button {
  display: block;
  width: 100%;
  padding: $gutter-v $gutter-h * 1.5;
  background-color: $color-highlight;
  border: none;
  color: $color-foreground-1;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xs);
  line-height: 1;
  cursor: pointer;

  @include break(md) {
    width: auto;
  }
}

.Banner-arrow {
  display: inline;
  margin-left: $gutter-h / 2;
  transition: transform .2s;
}

.Competitions-Banner {
  .Banner {
    margin-top: $gutter-h*2;
  }
}
