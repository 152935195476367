.Search {
  position: relative;
  margin-bottom: $gutter-v;

  @include break(md) {
    margin-bottom: $gutter-v*1.75;
    box-shadow: $box-shadow-low;
    border-bottom-left-radius: $border-radius-1;
    border-bottom-right-radius: $border-radius-1;

    &--no-shadow {
      box-shadow: none;
    }
  }
}

.Search-input {
  position: relative;
  width: 100%;

  &::before {
    position: absolute;
    top: $gutter-v/0.8;
    left: $gutter-h/2;
    width: $gutter-h/2;
    height: $gutter-v/2;
    margin: auto;
    background-size: contain;
    background-image: url(cdn_image('/img/icon-search.svg'));
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    content: '';
    opacity: .66;

    @include break(md) {
      top: 0;
      bottom: 0;
      left: $gutter-h;
      width: $gutter-h;
      height: $gutter-v;
    }
  }

  @include break(md) {
    &.is-active {
      .Search-input-btn {
        opacity: 1;
        transform: scale(1);
      }

      .Search-toggle {
        transform: translateX(-10rem);
      }
    }
  }

  input:not([type=submit]) {
    position: relative;
    padding-top: $gutter-v;
    padding-right: $gutter-h;
    padding-bottom: $gutter-v;
    padding-left: $gutter-h*1.5;
    margin: 0;
    background-color: $color-background-1;
    color: $color-foreground-1;
    font-family: $font-primary;
    font-size: map_get($font-size-primary, xs);
    font-weight: 500;
    border-top-left-radius: $border-radius-1;
    border-top-right-radius: $border-radius-1;
    transition: background-color .2s;
    height: 100%;

    &::placeholder {
      color: $color-foreground-4;
    }

    &:focus {
      background-color: $color-background-3;
      box-shadow: none;
    }

    @include break(md) {
      padding-top: $gutter-v*1.1;
      padding-bottom: $gutter-v*1.1;
      padding-right: 30rem;
      padding-left: $gutter-h*2.75;
      font-size: map_get($font-size-primary, md);
    }
  }

  &--single {
    input[type=text] {
      background-color: $color-background-3;
      border-bottom-left-radius: $border-radius-1;
      border-bottom-right-radius: $border-radius-1;

      &:focus {
        background-color: $color-background-1;
      }
    }
  }

  &-btn {
    display: none;

    &:disabled {
      background-color: $color-greyscale-8;
      cursor: default;
    }

    @include break(md) {
      display: block;
      position: absolute;
      right: $gutter-h/2.5;
      top: $gutter-v/2.5;
      bottom: $gutter-v/2.5;
      margin: auto;
      padding: $gutter-v*0.5 $gutter-h*1.5;
      background-color: $color-light;
      color: $color-foreground-1;
      font-family: $font-secondary;
      font-size: map_get($font-size-secondary, xs);
      border-width: 0;
      border-radius: $border-radius-1;
      cursor: pointer;
      opacity: 0;
      transform: scale(0);
      transition: opacity .5s, transform .25s;
    }
  }
}

.Search-toggle {
  position: relative;
  width: 100%;
  margin-bottom: $gutter-v*1.5;
  padding: $gutter-v $gutter-h;
  background-color: $color-light;
  cursor: pointer;
  border-bottom-left-radius: $border-radius-1;
  border-bottom-right-radius: $border-radius-1;
  box-shadow: $box-shadow-low;
  transition: transform .25s;

  @include break(md) {
    position: absolute;
    max-width: 18rem;
    right: $gutter-h/2;
    top: $gutter-v*0.75;
    bottom: $gutter-v*0.75;
    margin: auto;
    padding-top: $gutter-v/2;
    background-color: transparent;
    text-align: right;
    box-shadow: none;
  }

  input[type=checkbox] {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;

    &:checked ~ .Search-toggle-icon {
      background-image: url(cdn_image('/img/icon-toggle-on.svg'));
    }

    &:checked ~ .Search-toggle-label {
      color: $color-foreground-1;
    }
  }

  &-icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: $gutter-h*1.25;
    margin: auto;
    background-repeat: no-repeat;
    background-size: $gutter-h*1.25 auto;
    background-image: url(cdn_image('/img/icon-toggle-off.svg'));
    background-position: right center;
    pointer-events: none;
  }

  &-label {
    margin-right: $gutter-h*2;
    color: $color-foreground-4;
    font-family: $font-primary;
    font-size: map_get($font-size-primary, sm);
    font-weight: 500;
    white-space: nowrap;
  }

  &--hiddenDesktop {
    @include break(md) {
      display: none;
    }
  }
}

.Search-options {
  display: none;
  justify-content: flex-start;
  flex-wrap: wrap;

  &--open {
    display: flex;
  }

  @include break(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: $gutter-h/1.5 $gutter-h/2;
    background-color: $color-light;
  }

  &-separator {
    display: none;

    @include break(md) {
      display: block;
      height: 3.5rem;
      min-width: 1px;
      border-left: 2px $color-background-2 solid;
    }
  }

  &--hiddenMobile {
    @include breakMax(md) {
      display: none !important;
    }
  }

  &--hiddenDesktop {
    @include break(md) {
      display: none !important;
    }
  }

  &--advanced {
    display: none;

    &.Search-options--open {
      display: flex;

      @include break(md) {
        padding-top: 0;
      }
    }
  }

  &--pt-zero {
    padding-top: 0;
  }
}

.Search-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: $gutter-v $gutter-h;
  margin-bottom: $gutter-h/2;
  background-color: $color-light;
  color: $color-foreground-3;
  font-family: $font-secondary;
  font-size: map_get($font-size-secondary, xxs);
  border-radius: $border-radius-1;
  box-shadow: $box-shadow-low;

  @include break(md) {
    flex-wrap: nowrap;
    justify-content: space-around;
    width: auto;
    margin-bottom: 0;
    padding: $gutter-v/1.5 $gutter-h/4;
    box-shadow: none;
  }

  &--justifyCenter {
    justify-content: center;
  }

  &.is-active {
    position: relative;
    align-self: auto;
    color: $color-foreground-1;
    cursor: auto;

    &::after {
      position: absolute;
      bottom: -($gutter-h/1.5);
      right: -($gutter-h/3);
      left: -($gutter-h/3);
      content: '';
      border: 2px solid $color-action-1;

      @include break(xl) {
        right: 0;
        left: 0;
      }

      @include break(xxl) {
        right: 5%;
        left: 5%;
      }
    }
  }

  &-logo {
    display: block;
    width: 40%;
    max-width: 12rem;
    margin: 0 5%;
    padding-right: $gutter-h;
    background-image: url(cdn_image('/img/icon-external.svg'));
    background-size: 1.2rem auto;
    background-repeat: no-repeat;
    background-position: center right;

    @include break(md) {
      width: 12%;
      max-width: 13rem;
      margin: 0;
    }
  }

  &-label {
    font-family: $font-secondary;
    font-size: map_get($font-size-secondary, xs);
    cursor: pointer;

    @include break(md) {
      font-size: map_get($font-size-secondary, sm);
    }
  }
}

.Search-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding: $gutter-v/2 $gutter-h/1.5;
  margin-right: $gutter-h/2;
  margin-bottom: $gutter-h/2;
  background-color: $color-background-3;
  border-radius: $border-radius-1/2;

  @include break(md) {
    margin-right: 0;
    margin-bottom: 0;
    padding: $gutter-h/1.5 0;
    background-color: transparent;
  }

  &--boxed {
    @include break(md) {
      flex-basis: 31.33%;
      justify-content: center;
      margin: 0 1%;
      padding: $gutter-v/1.25 $gutter-h;
      background-color: $color-background-2;
      border-radius: $border-radius-1;
    }
  }

  &-icon {
    height: $gutter-v/1.33;
    width: $gutter-h/1.33;
    background-color: $color-background-4;
    opacity: .33;
    transform: scale(.75);
    transition: opacity .2s, transform .2s;
    pointer-events: none;
    border-radius: $border-radius-1/2;
  }

  input:checked ~ .Search-checkbox-icon {
    background-image: url(cdn_image('/img/icon-selected-on.svg'));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    transform: scale(1);
  }

}

.Search-radio {
  position: relative;
  display: flex;
  align-items: center;
  flex-basis: 33.33%;

  @include break(md) {
    flex-basis: auto;
    padding: 0 $gutter-h/4;
  }

  @include break(lg) {
    padding: 0 $gutter-h/2;
  }

  &-icon {
    height: $gutter-v/2;
    width: $gutter-h/2;
    background-color: $color-foreground-5;
    pointer-events: none;
    border-radius: 50%;
    transition: background-color .2s, box-shadow .2s, transform .2s;
  }

  input:checked ~ .Search-radio-icon {
    background-color: $color-highlight;
    border: 1px solid rgba($color-light, .66);
    box-shadow: 0 0 0.4rem 0 rgba($color-highlight, .75);
    transform: scale(1.25);
  }
}

.Search-checkbox,
.Search-radio {

  input {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;

    &:checked ~ .Search-checkbox-label,
    &:checked ~ .Search-radio-label {
      color: $color-foreground-1;
    }
  }

  &-label {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: $gutter-h/2;
    color: $color-foreground-3;
    font-size: map_get($font-size-secondary, xxs);
    font-family: $font-secondary;
    line-height: 1;
    cursor: pointer;

    @include break(lg) {
      font-size: map_get($font-size-secondary, xs);
    }
  }
}

.Search-dropdown {
  position: relative;
  display: flex;
  flex-basis: 100%;
  padding: $gutter-v/1.33 $gutter-h/1.5;
  margin: $gutter-v/3 0;
  appearance: none;
  outline: none;
  border: $border-thin-2;
  background-color: transparent;
  background-image: url(cdn_image('/img/icon-drop.svg'));
  background-repeat: no-repeat;
  background-position: center right ($gutter-h/1.5);
  background-size: 1rem auto;
  color: $color-foreground-3;
  font-size: map_get($font-size-secondary, xxs);
  font-family: $font-secondary;
  border-radius: $border-radius-1;
  cursor: pointer;
  overflow: hidden;

  @include break(md) {
    flex-basis: 31.33%;
    margin: 0 1%;
  }

  &:focus {
    color: $color-foreground-1;
  }

  &::placeholder {
    color: $color-foreground-3;
  }
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Search-download {
  display: flex;
  justify-content: flex-start;
  flex-basis: 100%;
  flex-grow: 1;
  margin: $gutter-v/3 0;
  padding: $gutter-v/1.33 $gutter-h/1.25;
  background-color: $color-background-1;
  background-image: url(cdn_image('/img/icon-download-dark.svg'));
  background-size: 1rem auto;
  background-repeat: no-repeat;
  background-position: right $gutter-h*1.5 center;
  color: $color-foreground-1;
  font-family: $font-secondary;
  font-size: map_get($font-size-secondary, xxs);
  border-radius: $border-radius-1;

  @include break(md) {
    flex-basis: 31.33%;
    justify-content: center;
    margin: 0 1%;
  }

  &:hover {
    background-color: $color-background-3;
  }
}

.Search-container {
  position: relative;
}

$loading-size: $gutter-h*2;

.Search-loading {
  position: fixed;
  display: none;
  top: 45vh;
  left: 0;
  right: 0;
  width: $loading-size;
  height: $loading-size;
  margin: auto;
  border: $gutter-v/3 solid $color-highlight;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite rotate-forever;
  pointer-events: none;
  z-index: 999;

  &--active {
    display: block;
  }
}

.Search-autocomplete {
  position: absolute;
  width: 100%;
  background-color: $color-light;
  z-index: 1;
  cursor: pointer;
  border-radius: $border-radius-1;
  box-shadow: $box-shadow-low;
  top: 6.6rem;
  color: $color-foreground-1;
  display: none;
  overflow-y: auto;
  max-height: 20.5rem;

  .option {
    padding: $gutter-v / 2;
    border-bottom: $border-thin-2;
  }

  &--show {
    display: block;
  }
}
