
.GeneralRanks {
  $borderStyle: 1px solid $color-foreground-4;
  padding: $gutter-v/2 $gutter-h/2 $gutter-v;
  background: $color-light;
  overflow: scroll;

  &-title {
    margin-bottom: $gutter-v;
    font-size: map-get($font-size-primary, md);
    text-align: center;
  }

  &-table {
    margin: 0 auto;
    color: $color-foreground-1;
    border-collapse: collapse;
    border-spacing: 0;

    td, th {
      padding: $gutter-h/4;
    }
  }

  &-filter {
    text-align: left;

    .NavLogo {
      display: inline-block;
      max-width: 14rem;
      margin: 0 0 $gutter-v;
    }

    p {
      margin: $gutter-v/4 0;
    }
  }

  &-header {
    width: 4.5rem;
    padding-bottom: $gutter-v/2;
    color: $color-foreground-2;
    border-left: $borderStyle;
    vertical-align: bottom;

    & > span {
      display: inline-block;
      transform: rotate(180deg);
      white-space: nowrap;
      writing-mode: tb-rl;
    }

    &--total {
      color: $color-highlight-2;
    }
  }

  &-fencer {
    border-top: $borderStyle;

    &Rank {
      text-align: right;
    }

    &Name {
      max-width: 20rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &Result {
      text-align: center;
      color: $color-dark;
      border-left: $borderStyle;

      &--total {
        color: $color-foreground-1;
        font-weight: 500;
      }
    }
  }
}
