.VideoGrid {
  margin-bottom: $gutter-v * 2;

  @include break(md) {
    margin-bottom: $gutter-v * 4;
  }
}

.VideoGrid-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: $container-width-1;

  @include break(md) {
    justify-content: flex-start;
    max-width: 100%;
  }
}
