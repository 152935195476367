.Snapshot {
  margin-bottom: $gutter-v * 1.5;

  @include break(sm) {
    margin-bottom: $gutter-v * 2;
  }

  @include break(md) {
    margin-bottom: $gutter-v * 3;
  }

  &-container {
    background-color: $color-light;
    border-radius: $border-radius-1;
    box-shadow: $box-shadow-low;
    position: relative;
  }

  .MoreLabel {
    &.ranking {
      position: absolute;
      width: 50%;
      bottom: $gutter-v;
      right: 0;
      left: 50%;

      @include breakMax(md) {
        display: none;
      }
    }
  }
}
