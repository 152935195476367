.ShareBar {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: auto;
  background-color: $color-background-5;

  @include break(sm) {
    max-width: 28rem;
  }

  .ShareBar-btn {
    display: block;
    flex-basis: 20%;
    padding-top: $gutter-v / 1.75;
    padding-bottom: $gutter-v / 2;
    padding-right: 7.5%;
    padding-left: 7.5%;
    text-align: center;

    @include break(sm) {
      padding-right: 6.66%;
      padding-left: 6.66%;
    }
  }
}

.ShareBar--relative {
  position: relative;
  margin-left: 0;
}
