$video-duration-bg-color: #9195BA;

.VideoCard {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $gutter-v;
  padding: ($gutter-v / 1.25) ($gutter-h / 1.25);
  overflow: hidden;
  background-color: $color-light;
  box-shadow: $box-shadow-low;
  border-radius: $border-radius-1;
  font-family: $font-secondary;
  transition: transform .2s, box-shadow .2s;

  @include break(md) {
    flex-basis: 48.66%;
    margin-right: 2.66%;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  @include break(lg) {
    flex-basis: 32%;
    margin-right: 2%;

    &:nth-of-type(2n) {
      margin-right: auto;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  &:hover {
    transform: scale(1.015);
    box-shadow: $box-shadow-soft;
  }
}

.VideoCard-frame {
  position: relative;
  width: 100%;
  margin-bottom: $gutter-v;
}

.VideoCard-image {
  padding-top: 56.25%;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  width: 100%;
}

.VideoCard-type {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  color: $color-light;
  font-size: map-get($font-size-secondary, 3xs);
  background-color: $video-duration-bg-color;
  letter-spacing: $letter-spacing-1;
}

.VideoCard-icon {
  background-color: $color-action-1;
  width: 1rem;
  height: .8rem;
}
