.ReportWrapper {
  display: flex;
  flex-direction: column;
}

.Report {
  flex: 1 1 auto;
  margin: $gutter-v 0 $gutter-v*2;
  background-color: $color-light;
  border-radius: $border-radius-2;
  box-shadow: $box-shadow-low;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 11rem;
    justify-content: space-around;
  }

  &-content {
    margin: $gutter-v $gutter-h;
    padding: $gutter-v $gutter-h;
    border-top: 1px solid $color-foreground-5;
  }

  &-item {
    font-family: $font-secondary;
    font-weight: 500;
  }

  .Filter-toggles-item {
    margin-left: $gutter-h;
  }
}
