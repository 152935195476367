.FAQ {
  .QuestionCard {
    overflow: hidden;

    @include break(md) {
      flex-basis: 100%;
    }

    .QuestionCard-content {
      @include break(md) {
        height: auto;
      }
    }
  }
}