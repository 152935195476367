.Container {
  width: 100%;
  margin: 0 auto;
  padding-left: $gutter-h/2;
  padding-right: $gutter-h/2;

  @include break(sm) {
    padding-left: $gutter-h * 1.5;
    padding-right: $gutter-h * 1.5;
  }

  @include break(md) {
    padding-left: $gutter-h * 2;
    padding-right: $gutter-h * 2;
  }

  &-backgroundImage {
    position: absolute;
    width: 100%;
    height: 24rem;
    background-size: cover;
    background-position: center;
    top: 5.1rem;
    left: 0;
    z-index: 0;

    @include break(md) {
      top: 8.6rem;
      height: 30rem;
    }

    @include break(lg) {
      top: 9.7rem;
      height: 40rem;
    }
  }
}

.Container--paddingVertical {
  padding-top: $gutter-v * 1.5;
  padding-bottom: $gutter-v * 1.5;

  @include break(md) {
    padding-top: $gutter-v * 4;
    padding-bottom: $gutter-v * 3;
  }

  @include break(lg) {
    padding-top: $gutter-v * 4.5;
    padding-bottom: $gutter-v * 3.5;
  }
}

.Container--full {
  max-width: 100%;
}

.Container--narrow {
  max-width: $container-width-1;
}

.Container--wide {
  max-width: $container-width-2;
}

.Container--wider {
  max-width: $container-width-3;
}

.Container--high-sm {
  min-height: $container-width-1 / 4;
}

.Container--high-md {
  min-height: $container-width-1 / 2;
}

.Container--high-lg {
  min-height: $container-width-1;
}

.Container-noContentMessage {
  color: $color-foreground-1;
}

.Container--marginTop {
  margin-top: $gutter-v;

  @include break(md) {
    margin-top: $gutter-v * 2;
  }
}
