/**
 * Content
 *
 * Example HTML:
 *
 * <div class="Content">
 *   ...
 * </div>
 */

.Content {
}

.Content--rel {
  position: relative;
}

/**
 * Content--pad-[size]
 *
 * Example HTML:
 *
 * <div class="Content--pad-[size]">
 *   ...
 * </div>
 */

.Content--pad-sm {
  padding: $gutter-v / 2 $gutter-h / 4;

  @include break(md) {
    padding: $gutter-v $gutter-h / 2;
  }
}

.Content--pad-md {
  padding: $gutter-v $gutter-h / 2;

  @include break(md) {
    padding: $gutter-v * 2 $gutter-h;
  }
}

.Content--pad-lg {
  padding: $gutter-v * 2 $gutter-h;

  @include break(md) {
    padding: $gutter-v * 3 $gutter-h * 2;
  }
}

.Content--pad-xl {
  padding: $gutter-v * 2 $gutter-h;

  @include break(sm) {
    padding: $gutter-v * 4 $gutter-h * 1.5;
  }

  @include break(md) {
    padding: $gutter-v * 4 $gutter-h * 5;
  }
}

/**
 * Content--centered
 *
 * Example HTML:
 *
 * <div class="Content--centered">
 *   ...
 * </div>
 */

.Content--centered {
  text-align: center;
}
