img {
  max-width: 100%;
  height: auto;
}

.iframe {
  position: relative;
  width: 100%;
  padding-top: 60%;

  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
