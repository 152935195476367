.RankingGrid {
  flex-direction: row;
}

.RankingGrid-main {

  .Table {
    margin-top: 0;
  }

  .Table-description {
    font-size: map-get($font-size-primary, xxs);

    @include break(md) {
      font-size: map-get($font-size-primary, xs);
    }
  }

  .Table-countryName {
    padding-left: .5rem;
    text-transform: capitalize;
  }

  .Table-countryName--hideSmall {
    display: none;

    @include break(md) {
      display: initial;
    }
  }

  .GridPaginator {
    border-top: $border-thin;
    border-bottom: $border-thin;
    margin-top: $gutter-v;
  }
}

.RankingGrid-forms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;

  @include break(md) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    select {
      flex-basis: 100%;
    }
    input {
      flex-basis: 48%;
    }
  }

  @include break(lg) {
    flex-wrap: nowrap;

    input,
    select {
      flex-basis: 32%;
    }
  }

  select.Form-dropdown {
    margin: $gutter-v/2 0;
    padding-top: $gutter-v;
    padding-right: $gutter-h*1.5;
    padding-bottom: $gutter-v;
    color: $color-foreground-1;
  }
}
