.CompetitionContent {
  padding: $gutter-v $gutter-h;
  color: $color-foreground-1;
  line-height: 1.5;

  @include break(md) {
    padding: ($gutter-v * 2) ($gutter-h * 2);
  }
}

.CompetitionContent-button {
  margin-left: 0;
  margin-bottom: $gutter-v / 1.5;
}

.CompetitionContent-download {
  display: flex;
  justify-content: flex-end;
  margin-top: $gutter-v*0.5;
  margin-bottom: $gutter-v*0.5;

  &-btn {
    display: block;
    position: relative;
    margin-right: $gutter-h;
    padding-right: $gutter-h;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xxs);
    line-height: 1;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: $gutter-v / 1.75;
      background-repeat: no-repeat;
      background-size: auto ($gutter-v / 1.75);
      background-image: url('/img/icon-download-overlay.svg');
      background-position: center right;
      opacity: .5;
      transition: opacity .3s, transform .3s;
    }

    @include break(md) {
      margin-right: 0;
      margin-left: $gutter-h;
    }
  }
}

.CompetitionContent-sponsors {
  padding: $gutter-h $gutter-h;
  background-color: $color-background-1;

  @include break(md) {
    padding: $gutter-h ($gutter-h * 2);
  }

  &-label {
    color: $color-foreground-3;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xs);
    line-height: 1;
  }

  &-logos {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      display: block;
      filter: sepia(100%) hue-rotate(200deg) saturate(150%);
      opacity: .5;
      transition: opacity .3s;

      &:hover {
        filter: sepia(0%) hue-rotate(0deg) saturate(100%);
        opacity: .9;
      }
    }

    img {
      max-height: 3.5rem;
    }
  }
}
