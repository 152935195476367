.CompetitionHero {
  text-align: center;
  background: url(../img/executive-committee-header-bg.jpg) no-repeat center;
  background-size: cover;

  @include break(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 2*$gutter-v;
    padding-bottom: 2*$gutter-v;
    /* text-align: left; */
  }

  &-title {
    position: relative;
    display: block;
    margin-top: 0;
    margin-bottom: $gutter-v;
    color: white;
    font-family: 'futurabold';
    font-size: map-get($font-size-secondary, xxl);

    @include break(md) {
      font-size: map-get($font-size-secondary, 4xl);
    }
  }

  &-subtitle {
    margin-top: 0;
    margin-bottom: $gutter-v/2;
    font-family: 'futurapt';
    font-weight: 100;
    font-size: 1em;
    color: white;
    letter-spacing: $letter-spacing-2;
    text-transform: capitalize;
     @include break(md) {
        font-size: map-get($font-size-secondary, xl);
     }
  }

  &-button {
    margin-bottom: $gutter-v*1.5;
  }

  &-frame {
    width: 100%;
    max-width: 10rem;
    margin: 0 auto $gutter-v*1.5;
    background-color: $color-background-3;
    border-radius: $border-radius-circle;
    overflow: hidden;
    border: .4rem solid $color-light;
    box-shadow: $box-shadow-low;

    @include break(md) {
      max-width: 12.5rem;
      margin-left: 0;
      margin-right: $gutter-h*2;
      margin-bottom: 0;
    }
  }

  &-image {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-position: center;
    opacity: .8;

  }

  &-date {
    margin-right: $gutter-h/1.75;
    padding-right: $gutter-h/1.75;
    border-right: 2px solid $color-foreground-5;
  }

  &-info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: 'futurabold';
    font-size: map-get($font-size-secondary, md);
    color: $color-foreground-2;
    text-transform: capitalize;

    &-label {
      display: flex;
      margin: 0 $gutter-h/4 $gutter-v/2;
      padding: $gutter-v/3 $gutter-h/0.5;
      background-color: $color-background-3;
      /* border-radius: $border-radius-1*10; */

      div:last-of-type {
        margin-left: $gutter-h/4;
        color: $color-foreground-1;
        font-family: 'futurapt';
      }

      @include break(md) {
        margin-left: 0;
        margin-right: $gutter-h/2;
      }
      @media screen and (max-width: 992px) {
        width: 47%;
        justify-content: center;
      }
      @media screen and (max-width: 768px) {
        margin: 0;
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}
