.Content-cards {
  .MoreLabel {
    margin-bottom: $gutter-v;
  }

  &--firstLarge {
    @include break(md) {
      width: 70%;
      display: inline-block;
      margin-right: $gutter-h;

      .MoreLabel {
        margin-bottom: 0;
      }
    }

    a:first-child {
      padding: 0;
      flex-direction: column;
      overflow: hidden;

      .ContentCard-frame {
        width: 100%;
        border-top-left-radius: $border-radius-2;
        border-top-right-radius: $border-radius-2;
      }

      .ContentCard-image {
        padding-top: 60%;
        border-top-left-radius: $border-radius-2;
        border-top-right-radius: $border-radius-2;
      }

      .ContentCard-content {
        padding: $gutter-v/2;
      }
    }
  }
}

.Featured-links {

  @include breakMax(md) {
    background-color: $color-light;
    border-radius: $border-radius-2;
    border: 1px solid $color-foreground-5;
    box-shadow: $box-shadow-soft;

    .Featured-link {
      .Link-card {
        border-bottom: 1px solid $color-foreground-5;
        background-image: none !important;

        .Text {
          width: 100%;
          padding-left: $gutter-h*0.75;

          .Link {
            padding: map-get($font-size-primary, xxs) 0;
            font-family: $font-primary;
            font-weight: 400;

            .Banner-arrow {
              display: none;
            }
          }
        }
      }
    }
  }

  @include break(md) {
    width: calc(30% - 2rem - 6px);
    display: inline-block;
    vertical-align: top;

    .Featured-link {
      .Link-card {
        border-bottom: 0;

        .Text {
          text-align: left;
          font-family: $font-secondary;
          padding-left: $gutter-h*0.75;

          .Link {
            padding: map-get($font-size-primary, sm) 0;

            .Banner-arrow {
              height: map-get($font-size-primary, 3xs);
            }
          }
        }
      }

      &--separator {
        .Link-card {
          border-bottom: 1px solid $color-foreground-5;
        }
      }

      &--image {
        .Link-card {
          height: 18rem;
          width: 100%;
          border-radius: $border-radius-2;
          left: 0;
          background-size: cover;
          background-position: center;
          position: relative;
          margin-bottom: $gutter-h/2;

          &:before, &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: $border-radius-2;
          }

          &:before {
            background-color: #122B58;
            opacity: 0.09;
          }

          &:after {
            background-color: #2E2C7E;
            opacity: 0.33;
          }

          &:hover {
            box-shadow: $box-shadow-soft;
            transform: scale(1.015);
          }

          .Text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            .Link {
              position: relative;
              z-index: 1;
              color: $color-light;
              padding: map-get($font-size-primary, xxs) 0;
            }

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              border-bottom-left-radius: $border-radius-2;
              border-bottom-right-radius: $border-radius-2;
              height: map-get($font-size-primary, xx);
              background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
            }
          }
        }
      }
    }
  }
}
