.CompetitionsTable {
  border-spacing: 0;
  padding: 0 !important;
  box-shadow: $box-shadow-even !important;

  .Table-head {
    .CompetitionsTable-row:hover {
      background-color: transparent;
    }
  }

  .CompetitionsTable-row {
    display: table-row;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $color-background-3;
    }

    .Table-cell {
      border-bottom: $border-thin-2;
      overflow: hidden;
    }

    &:last-of-type {
      .Table-cell {
        border-bottom: none;
      }
    }

    &:only-of-type {
      .Table-cell {
        border-bottom: $border-thin-2;
      }
    }

    &--hide {
      display: none;
    }

    &-subCompetition {
      background-color: $color-background-2;

      td {
        padding: $gutter-v/2.25 $gutter-h/2.25;

        @include break(md) {
          padding: $gutter-v/2 $gutter-h/2;
        }
      }

      td:first-of-type, td:nth-of-type(2) {
        padding-left: 3*$gutter-h/2;
      }
    }

    &-superCompetition {
      td:first-of-type, td:nth-of-type(2) {
        position: relative;
        padding-left: 3*$gutter-h/2;

        &:before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-bottom: 5px solid transparent;
          border-top: 5px solid transparent;
          border-left: 5px solid $color-foreground-2;
          top: $gutter-v;
          left: $gutter-h*0.8;
          transition: transform 0.5s;
        }
      }
    }

    &-superCompetition--open {
      td:first-of-type, td:nth-of-type(2) {
        &:before {
          transform: rotate(90deg);
        }
      }
    }

    &--even {
      background-color: $color-background-1;
    }
  }

  .Table-body {
    font-family: $font-primary;
  }
}
