.FencerDetail {
  width: 50%;
  margin-right: -1px;
  display: none;
  vertical-align: top;

  @include breakMax(md) {
    display: none;
  }

  .Fencer {
    display: none;

    .Fencer-image {
      width: 100%;
      height: 35rem;
      background-color: $color-background-1;
      background-size: cover;
      background-position: top center;

      @include break(lg) {
        height: 38rem;
      }
    }

    .Fencer-description {
      padding: $gutter-v;

      .TopFencers-athlete-name, .Tags {
        font-family: $font-secondary;
        text-transform: capitalize;
      }
    }

    .description {
      font-size: map-get($font-size-secondary, xs);
      padding-top: $gutter-v / 2;
      color: $color-greyscale-6;
      overflow: auto;
      max-height: 10.2rem;
    }

    .Tags {
      color: $color-foreground-3;
      font-size: map-get($font-size-secondary, 3xs);

      span {
        padding-right: $gutter-h;
        position: relative;

        &:after {
          position: absolute;
          right: calc(#{$gutter-h / 2} - 1.5px);
          top: 0.6rem;
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: $color-foreground-3;
        }

        &:last-child {
          &:after {
            background-color: transparent;
          }
        }
      }
    }

    &.active {
      display: block;
    }
  }

  .MoreLabel {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
