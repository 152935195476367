.QuestionCard {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-grow: 0;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  margin: $gutter-v/2 0;
  font-family: $font-secondary;
  background-color: $color-light;
  border-radius: $border-radius-2;
  box-shadow: $box-shadow-low;
  transition: transform .2s, box-shadow .2s;

  @include break(md) {
    flex-basis: 49%;
    margin: 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      height: map-get($font-size-primary, lg);
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 33%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 7rem;
      left: 0;
      width: 100%;
      z-index: 1;
      height: map-get($font-size-primary, lg);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 66%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
  }
}

.QuestionCard-profilePicture {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 5rem;
  height: 5rem;
  background-color: $color-foreground-6;
  background-size: cover;
  background-position: center top;
  border-radius: $border-radius-circle;
}

.QuestionCard-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: $gutter-v $gutter-h;

  @include break(md) {
    height: 18rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.QuestionCard-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: $gutter-v/2 $gutter-h;
  background-color: $color-background-1;
  border-bottom-right-radius: $border-radius-2;
  border-bottom-left-radius: $border-radius-2;
}

.QuestionCard-date {
  color: $color-foreground-4;
  font-size: map-get($font-size-primary, xxs);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-1;
}

.QuestionCard-question {
  margin-top: $gutter-v/2;
  margin-bottom: $gutter-v/2;
  color: $color-foreground-1;
  font-size: map-get($font-size-primary, md);
  font-weight: 600;
  max-width: 100%;
}

.QuestionCard-answer {
  color: $color-greyscale-6;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, md);
  font-weight: 400;
  line-height: 1.2;
  max-width: 100%;
  word-wrap: break-word;
}

.QuestionCard-authorMeta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: $gutter-v;
  line-height: 1.5;
}

.QuestionCard-authorName {
  color: $color-foreground-2;
  font-size: map-get($font-size-primary, xs);
  font-weight: 600;
}

.QuestionCard-authorCountry {
  color: $color-foreground-4;
  font-size: map-get($font-size-primary, xxs);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-1;
}

.QuestionCard-authorFlag {
  max-width: 1.5rem;
  margin-right: $gutter-h/3;
}
