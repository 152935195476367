ul.Tags {
  padding: 0;
  color: $color-foreground-3;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, sm);
  font-weight: 300;
  line-height: 1.75;
  list-style: none;

  .Tags-label {
    &::after {
      content: '';
    }
  }

  li {
    display: inline;
    margin-right: $gutter-h / 4;

    &::after {
      content: ',';
    }

    &:last-of-type {
      &::after {
        content: '';
      }
    }
  }

  a {
    color: $color-foreground-2;
    border-bottom: $border-thin;
    transition: border-bottom .2s;

    &:hover,
    &:focus {
      border-bottom: $border-thin--dark;
    }
  }
}
