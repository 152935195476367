.AthleteHero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ($gutter-v * 1.5) 0;
  font-family: $font-secondary;

  @include break(md) {
    justify-content: flex-end;
    padding-top: 4rem;
  }
}
.President--wider{
    max-width: 1108px;
}
.President {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $font-secondary;

  @include break(md) {
    justify-content: flex-end;
    padding-top: 15rem;
  }
}
.AthleteHero-bgFrame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  //background-color: $color-background-5;
  background: url(../img/executive-committee-header-bg.jpg) no-repeat center;
  background-size: cover;
  z-index: -1;
  overflow: hidden;
}

.AthleteHero-bg {
  position: absolute;
  top: -25px;
  left: -25px;
  right: -25px;
  bottom: -25px;
  margin: auto;
  background-size: cover;
  background-position: center;
  filter: blur(25px);
  opacity: .3333;
  display: none;
}

.AthleteHero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-light;

  @include break(md) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  }
}

.AthleteHero-fencerContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  order: 1;
  width: 100%;

  @include break(md) {
    flex-basis: 66.66%;
    flex-grow: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    order: 2;
  }
}
.PresidentImg {
  max-width: 100%;
  height: auto;
}
.PresidentBio {
    font-size: 1.8rem;
}
.AthleteHero-fencerInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  order: 1;
  margin-left: 0;
  text-transform: capitalize;

  @include break(md) {
    align-items: flex-start;
    order: 2;
    align-self: flex-start;
    margin-left: 5rem;
    margin-bottom: 0;
  }
}
.President-fencerInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  order: 1;
  margin-left: 0;
  text-transform: none;
  text-align: center;

  @include break(md) {
    align-items: flex-start;
    order: 2;
    align-self: flex-start;
    margin-left: 6rem;
    margin-bottom: 0;
  }
}

.AthleteHero-fencerName {
  display: flex;
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  color: $color-light;
  font-size: map-get($font-size-secondary, lg);
  font-family: 'futurabold';

  @include break(md) {
    margin-top: $gutter-v * 2;
    margin-bottom: 2rem;
    font-size: 4.2rem;
  }
}

.President-name {
  display: flex;
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  color: $color-light;
  margin-left: 10%;
  font-size: map-get($font-size-secondary, lg);
  font-family: 'futurabold';

  @include break(md) {
    margin-top: $gutter-v * 2;
    margin-bottom: 2rem;
    font-size: 2.6em;
  }
}
.President-sub {
  display: block ruby;
  margin-top: 0;
  color: $color-light;
  font-size: map-get($font-size-secondary, lg);
  font-family: 'futurapt';

  @include break(md) {
    font-size: 3em;
  }
}
.AthleteHero-nationality {
  display: flex;
  margin-bottom: $gutter-v * 1.5;
  color: $color-foreground-5;
  font-size: 6rem;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;

  @include break(md) {
    margin-bottom: 0;
  }
}

.AthleteHero-flag {
  margin-right: $gutter-v / 2;
}

.AthleteHero-cta {
  text-align: center;
  order: 3;
  width: 100%;

  .Button {
    margin: 2px;
  }

  @include break(md) {
    flex-basis: 33.33%;
    margin-left: .5rem;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    text-align: right;

    .Button {
      margin-top: $gutter-v / 4;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

.President-fencerFrame {
  order: 2;
  width: 100%;
  max-width: 372px;
  
  @include break(md) {
    order: 1;
    margin-bottom: 0;
  }
}

.AthleteHero-fencerFrame {
  order: 2;
  width: 100%;
  max-width: 256px;
  margin-bottom: $gutter-v * 1.5;

  @include break(md) {
    order: 1;
    margin-bottom: 0;
  }
}

.AthleteHero-fencerImage {
  padding-top: 100%;
  background-color: $color-background-2;
  background-size: cover;
  background-position: center top;
  border: .4rem solid $color-light;
  border-radius: $border-radius-circle;
  box-shadow: $box-shadow-even;
}
