.ExtraLinks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;

  &-item {
    display: block;
    flex-basis: 100%;
    padding: $gutter-v 0;
    background-color: $color-background-6;
    cursor: pointer;
    transition: background-color .2s;

    @include break(sm) {
      flex-basis: 50%;
      margin-top: $gutter-v;
    }

    &:nth-child(2) {
      background-color: $color-background-5;
    }

    &:hover {
      background-color: $color-background-4;
    }
  }

  &-label {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 3rem;
    background-image: url(cdn_image('/img/icon-download.svg'));
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 1.2rem auto;
    color: $color-light;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xs);
    line-height: 1;

    @include break(md) {
      font-size: map-get($font-size-secondary, sm);
    }

    &--external {
      background-image: url(cdn_image('/img/icon-external-light.svg'));
    }
  }
}
