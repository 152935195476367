$athleteCardImageSize: 10rem;
$profileMaxHeight-sm: 24rem;
$profileMaxHeight-md: 24rem;

.HeadToHead {
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xs);
  color: $color-foreground-1;

  .Table {
    padding: 0;
  }

  .Table-row {
    @include break(md) {
      margin-left: $gutter-v * 3;
      margin-right: $gutter-v * 3;
    }

    &--header {
      @include break(md) {
        margin: 0;
        padding: 0 $gutter-v * 3;
      }
    }
  }
}

.HeadToHead-section {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: $gutter-v*1.5;
  background: white;
  border-radius: 10px;
  overflow: hidden;

  @include break(md) {
    margin-bottom: $gutter-v*2;
  }
}

.HeadToHead-header {
  font-size: 24px;
  line-height: 1;
  padding: 20px;
  text-align: center;
  color: white;
  background: #182d70 url(cdn_image('/img/BCK1.jpg')) 50% 30%;
  font-family: 'futurabold';
  background-size: 100% auto;
}

.HeadToHead-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: $gutter-v $gutter-h;
}

.HeadToHead-icon {
  width: $gutter-h;
  height: $gutter-v;
  margin-right: $gutter-h;
  color: $color-light;
  background-color: $color-action-1;
  border-radius: $border-radius-circle;
  text-align: center;
  line-height: 1.2;
}

.HeadToHead-photo {
  display: block;
  width: 10rem;
  min-height: 10rem;
  @include break(md) {
    width: 19rem;
    min-height: 19rem;
  }
  margin: $gutter-v auto;
  background-color: $color-background-2;
  background-size: cover;
  background-position: center top;
  border-radius: $border-radius-circle;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.HeadToHead-card {
  padding: 0 15px;
  @include break(md) {
    padding: 0 35px;
  }
}

.HeadToHead-feature-line--odd {
  background: #efefef;
}

.HeadToHead-match-up {
  background: #d0d1d3 url(cdn_image('/img/BCK2.jpg')) 50% 30%;
  margin-top: 40px;
}

.HeadToHead-statistics-section {
  margin-top: 40px;

  .Grid:nth-child(2n + 1) {
    background: #efefef;
  }
}

.HeadToHead-match-lines {
  margin-bottom: 40px;
}

.HeadToHead-dropdown {
  background: #d0d1d3 url(cdn_image('/img/BCK2.jpg')) 50% 30%;
  .Form-dropdown.Form-dropdown--sm {
    margin: 0;
    max-width: none;
    border: none;
    background-color: transparent;
    border-radius: 0;
    text-align-last: center;
    background-image: url(cdn_image('/img/icon-drop-blue.svg'));

    option {
      background: #d0d1d3;
      text-align: left;
    }
  }
}

.HeadToHead-feature {
  font-size: map-get($font-size-secondary, 3xs);
  color: $color-foreground-3;
  letter-spacing: $letter-spacing-1;
}

.HeadToHead-stat {
  font-size: map-get($font-size-secondary, xxs);
}

.HeadToHead-feature,
.HeadToHead-stat {
  padding: $gutter-v ($gutter-h / 2);
  text-align: center;
  text-transform: uppercase;

  @include break(md) {
    padding: $gutter-v $gutter-h;
  }
}


.HeadToHead-feature {
  padding: $gutter-v 0;
}

.HeadToHead-matchName {
  margin-top: .5rem;
  color: $color-foreground-1;
  text-transform: capitalize;
}

.HeadToHead-matchTags {
  display: none;

  @include break(sm) {
    display: initial;
  }

  &--small {
    display: initial;

    @include break(sm) {
      display: none;
    }
  }

  span {
    padding-right: $gutter-h;
    position: relative;

    &:after {
      position: absolute;
      right: calc(#{$gutter-h / 2} - 1.5px);
      bottom: 0.6rem;
      content: '';
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: $color-foreground-3;
    }

    &:last-child {
      &:after {
        background-color: transparent;
      }
    }
  }
}

.HeadToHead-matchLocation {
  margin-top: $gutter-h/2;

  .Table-country {
    background-color: transparent;
  }
}

.HeadToHead-match {
  color: $color-foreground-3;

  &.HeadToHead-lost-left {
    text-indent: $gutter-h;
  }

  &.HeadToHead-won-left {
    color: $color-foreground-1;
    line-height: 19px;

    &::before {
      content: '•';
      font-size: map-get($font-size-secondary, lg);
      color: $color-foreground-1;
      line-height: 19px;
      vertical-align: text-bottom;
    }
  }

  &.HeadToHead-lost-right {
    text-indent: -$gutter-h;
  }

  &.HeadToHead-won-right {
    color: $color-foreground-1;

    &::after {
      content: '•';
      font-size: map-get($font-size-secondary, lg);
      color: $color-foreground-1;
      line-height: 19px;
      vertical-align: text-bottom;
    }
  }
}

.HeadToHead-country {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.HeadToHead-countryFlag {
  display: none;

  @include break(md) {
    display: flex;
  }
}

.HeadToHead-countryName {
  display: flex;

  @include break(md) {
    padding-left: $gutter-h / 2;
  }
}

.HeadToHead-feature-rows {
  height: $profileMaxHeight-sm;
  overflow: hidden;

  @include break(md) {
    height: $profileMaxHeight-md;
  }
}

.HeadToHead-feature-rows--expanded {
  height: 100%;
}
