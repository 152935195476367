.GridPaginator {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 $gutter-h;
  margin: $gutter-v 0;
  font-family: $font-secondary;
  border-top: 2px solid $color-foreground-5;
  border-bottom: 2px solid $color-foreground-5;

  @include break(md) {
    margin-top: $gutter-v*1.5;
    margin-bottom: 0;
  }
}

.GridPaginator-pagesContainer {
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin: 0 $gutter-h/2;
  font-size: map-get($font-size-secondary, xxs);

  @include break(md) {
    margin: 0 $gutter-h*3;
  }
}

.GridPaginator-pageNumber {
  position: relative;
  flex-basis: 20%;
  padding: $gutter-v*1.5 0;
  text-align: center;
  color: $color-foreground-3;
  cursor: pointer;
  transition: color .2s;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    width: 100%;
    max-width: 9rem;
    margin: auto;
    border-bottom: 2px solid $color-foreground-1;
    transform: scaleX(0);
    transition: transform .2s;

  }

  &:hover,
  &--active {
    color: $color-foreground-1;

    &::after {
      transform: scaleX(1);
    }
  }

  &--active {
    cursor: default;
  }
}

.GridPaginator-direction {
  padding: $gutter-v 0;
  color: $color-foreground-2;
  cursor: pointer;
  transition: color .2s;

  &:hover {
    color: $color-foreground-1;
  }

  &--disabled {
    color: $color-foreground-3;
    cursor: default;

    &:hover {
      color: $color-foreground-3;
    }
  }
}
