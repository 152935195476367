.Results {
  margin-bottom: $gutter-v * 1.5;

  @include break(sm) {
    margin-bottom: $gutter-v * 2;
  }

  @include break(md) {
    margin-bottom: $gutter-v * 3;
  }

  .Results-content {
    padding: $gutter-v $gutter-h;
    background-color: $color-light;
    border-bottom-right-radius: $border-radius-1;
    border-bottom-left-radius: $border-radius-1;
    box-shadow: $box-shadow-low;

    .MoreLabel {
      padding-top: $gutter-v;
    }
  }
}

.ResultsTable {
  width: 100%;
  color: $color-foreground-1;
  display: table;

  .tr {
    font-size: map-get($font-size-secondary, sm);
    display: table-row;

    .th {
      font-family: $font-secondary;
      padding: $gutter-v/3 0;
      display: table-cell;
      text-align: center;
    }

    .td {
      text-align: center;
      padding: $gutter-v/3 0;
      display: table-cell;
    }

    .th:first-child, .td:first-child {
      text-align: left;
    }

    @include breakMax(md) {
      .ResultsTable--hide {
        display: none;
      }
    }
  }

  .Flag-icon {
    margin-right: $gutter-h / 2;
    border: 1px solid $color-greyscale-8;
  }

  .name {
    margin-left: 35px;
  }
}
