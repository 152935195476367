button {
  border-radius: 0;
  box-shadow: none;
  appearance: none;
}

textarea,
input[type=text],
input[type=tel],
input[type=email],
input[type=search] {
  display: block;
  box-shadow: none;
  appearance: none;
  width: 100%;
  height: 6rem;
  padding: $gutter-v / 2;
  font-size: map-get($font-size-primary, sm);
  border: 0;
  transition: box-shadow .2s;

  &:focus {
    outline: none;
    box-shadow: 0 1rem 1.8rem 0 rgba($color-shadow, .1);
  }
}
