$tabMinHeight: 40rem;

.Tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Tabs-nav {
  width: 100%;
  margin: 0 auto;
  width: 100%;
}

.Tabs-nav-items {
  display: flex;
}

.Tabs-nav-item {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xxs);
  background-color: $color-background-1;
  line-height: 1;
  text-align: center;
  border-left: 1px solid $color-background-2;
  border-right: 1px solid $color-background-2;
  border-bottom: 2px solid $color-background-2;

  @include break(sm) {
    font-size: map-get($font-size-secondary, sm);
  }

  &:first-child {
    border-top-left-radius: $border-radius-1;
  }

  &:last-child {
    border-top-right-radius: $border-radius-1;

    &::after {
      border-left: 0;
    }
  }

  &::after {
    position: absolute;
    content: '';
    right: -1px;
    bottom: 0;
    height: 60%;
    border-left: $border-thin;
    z-index: 1;
  }

  &.is-active {
    background-color: $color-light;
    border-top: $border-highlight;
    border-bottom: 2px solid $color-background-1;
    z-index: 2;

    &::after {
      border-left: none;
    }
  }

  &--hiddenMobile {
    display: none;

    @include break(md) {
      display: block;
    }
  }
}

.Tabs-nav-item--sm {
  font-size: map-get($font-size-secondary, 3xs);

  &.is-active {
    border-top: none;
  }
}

.Tabs-nav-link {
  display: block;
  padding: $gutter-v 0;
  color: $color-foreground-3;
  cursor: pointer;
  transition: color .3s;

  @include break(sm) {
    padding: $gutter-v 0;
  }

  &--titleHiddenMobile {
    display: none;

    @include break(md) {
      display: inline;
    }
  }

  &:hover {
    color: $color-foreground-1;
  }
}

.Tabs-nav-item.is-active {

  .Tabs-nav-link {
    color: $color-foreground-1;
  }
}

.Tabs-itemsWrapper {
  width: 100%;
  background-color: $color-light;
  box-shadow:$box-shadow-low;
  border-bottom-right-radius: $border-radius-1;
  border-bottom-left-radius: $border-radius-1;
}

.Tabs-item {
  display: none;

  &.is-active {
    display: block;
  }
}
