.DiscoverCard {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 56rem;
  margin: ($gutter-v / 2.5) 0;
  padding: $gutter-v / 2;
  font-family: $font-secondary;
  background-color: $color-light;
  border-radius: $border-radius-1;
  box-shadow: $box-shadow-low;
  transition: transform .2s, box-shadow .2s;

  &:hover {
    box-shadow: $box-shadow-soft;
    transform: scale(1.015);
  }

  @include break(md) {
    padding: ($gutter-v / 1.5) ($gutter-h / 1.5);
  }

  @include break(lg) {
    width: 49.25%;
    max-width: 49.25%;
  }
}

.DiscoverCard-frame {
  position: relative;
  width: 33.33%;
  background-color: $color-background-3;
  overflow: hidden;
}

.DiscoverCard-image {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center top;
  opacity: .9;
}

.DiscoverCard-type {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: $gutter-v / 4;
  color: $color-light;
  font-size: map-get($font-size-secondary, 3xs);
  background-color: $color-foreground-3;

  @include break(sm) {
    font-size: map-get($font-size-secondary, xxs);
  }
}

.DiscoverCard-type--featured {
  background-color: $color-action-1;
}

.DiscoverCard-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: $gutter-h / 2;
  background-color: $color-light;

  @include break(sm) {
    padding-left: $gutter-v / 1.5;
  }
}

.DiscoverCard-date {
  margin-top: 0;
  margin-bottom: $gutter-v / 4;
  color: $color-foreground-4;
  font-size: map-get($font-size-secondary, 3xs);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;
}

.DiscoverCard-title {
  margin-top: 0;
  margin-bottom: $gutter-v / 3;
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, xs);
  font-weight: 600;
  line-height: 1.2;
  text-transform: none;
  max-width: 100%;

  @include break(sm) {
    font-size: map-get($font-size-secondary, sm);
    margin-bottom: $gutter-v / 2.5;
  }
}

.DiscoverCard-excerpt {
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  color: $color-foreground-3;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, xxs);
  font-weight: 500;
  line-height: 1.4;
  text-transform: none;
  max-width: 100%;

  @include break(sm) {
    font-size: map-get($font-size-primary, xs);
  }
}
