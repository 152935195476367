.Tableau-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  @include break(lg) {
    justify-content: flex-start;
    margin-top: $gutter-v*2;
  }
}

.Tableau {
  width: 100%;
}

.Tableau-container {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;

  & > :nth-last-child(2) {
    .Tableau-round:after {
      background-color: transparent;
    }

    .Tableau-fencers {
      margin-right: 0;
    }
  }
}

.Tableau-search {
  position: relative;
  width: 100%;
  margin: $gutter-v 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1.5rem;
    height: 100%;
    width: 1.5rem;
    margin: auto;
    background-size: contain;
    background-image: url(cdn_image('/img/icon-search.svg'));
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
  }

  input {
    position: relative;
    padding: $gutter-v/3 $gutter-h $gutter-v/3 $gutter-h*2;
    background-color: $color-background-2;
    color: $color-foreground-3;
    font-size: map_get($font-size-primary, xs);
    font-weight: 500;
    border: none;
    border-radius: $border-radius-1;

    &::placeholder {
      color: $color-foreground-4;
    }
  }
}

.Tableau-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $gutter-v/2
}

.Tableau-directionSquare {
  position: absolute;
  z-index: 1;
  padding: $gutter-h/2;
  top: $gutter-h/2;
  background-color: $color-background-2;
  cursor: pointer;

  &--next {
    right: 0;
  }
}

.Tableau-direction {
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(cdn_image('/img/icon-go.svg'));
  cursor: pointer;
  opacity: .5;

  &--prev {
    transform: rotate(180deg);
  }

  &--disabled {
    cursor: default;
  }
}

.Tableau-label {
  margin: $gutter-v 0;
  color: $color-foreground-2;
  font-family: $font-secondary;
  font-size: map_get($font-size-secondary, xxs);
  text-align: center;
  line-height: 1;
}

.Tableau-elimination {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: width .3s, opacity 1s;
  text-align: center;

  > div:nth-child(2n+3) {
    &:after {
      top: -$gutter-v / 8;
      height: calc(50% + #{$gutter-v/8});
    }
  }

  &.is-inactive {
    max-width: 3.5rem;
    cursor: pointer;

    .Tableau-round {
      background-color: $color-background-2;
      border: none;

      &::before {
        display: none;
      }

      & > * {
        visibility: hidden;
      }
    }

    .Tableau-country {
      display: none;
    }

    .Tableau-fencer {
      max-height: 2rem;
      background-color: $color-background-2;
      color: transparent;
    }

    .Tableau-fencerFlag {
      display: none;
    }

    &:hover {
      .Tableau-round {
        background-color: $color-background-1;
      }
      .Tableau-fencer {
        background-color: $color-background-1;
      }
    }
  }

  &--hide {
    width: 0;
    opacity: 0;
    overflow: hidden;
  }

  &--visible-2 {
    .Tableau-round {
      height: 13.4rem;
    }

    .Tableau-referees {
      top: 80%;
    }
  }

  &--visible-1 {
    .Tableau-round {
      height: 27.3rem;
    }

    .Tableau-referees {
      top: 66%;
    }
  }

  &--visible-0 {
    .Tableau-round {
      height: 55.1rem;
    }

    .Tableau-referees {
      top: 60%;
    }
  }

  &--visible--1 {
    .Tableau-round {
      height: 86.6rem;
    }
  }

  &--visible-3, &--visible-4, &--visible-5, &--visible-6, &--visible-7, &--visible-8 {
    display: none;
  }

  &:only-child {
    margin: 0 auto;
  }
}

.Tableau-other-results .Tableau-elimination.Tableau-elimination--other-results {
  > div:nth-child(2n+3) {
    &:after {
      top: 50%;
    }
  }
  > div:nth-child(2n+2) {
    &:after {
      top: -$gutter-v / 8;
      height: calc(50% + #{$gutter-v/8});
    }
  }
}

.Tableau-round {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  width: 100%;
  margin: $gutter-v/4 auto;
  background-color: $color-light;
  transition: height .3s;
  max-width: 50rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 1px;
    background-color: $color-background-2;
    z-index: 3;
  }

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(50% + #{$gutter-v/8});
    background-color: $color-background-2;
    z-index: 3;
    right: 0;
    top: 50%;
  }

  &--hide {
    opacity: 0.3;
  }

  @include breakMax(md) {
    &::after {
      height: 0;
    }
  }
}

.Tableau-fencers {
  border-radius: $border-radius-2;
  border: $border-thin;
  margin: 0 $gutter-h;
  overflow: hidden;
  position: relative;

  @include breakMax(md) {
    margin: 0;
  }
}

.Tableau-referees {
  position: absolute;
  text-align: left;
  left: 2rem;
}

.Tableau-fencer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-color: $color-victory;
  color: $color-foreground-1;

  opacity: 1;
  transition: background-color .3s;
  z-index: 2;

  &--defeated {
    background-color: $color-light;

    .Tableau-fencerScore {
      font-family: $font-primary;
      &:before {
        content: 'D/';
      }
    }
  }

  &--noMatch {
    .Tableau-fencerScore {
      font-size: map_get($font-size-primary, xl);
      line-height: map_get($font-size-primary, xs);

      &:before {
        content: '\2192';
      }
    }
  }
}

.Tableau-fencer--defeated.Tableau-fencer--noMatch {
  background-color: $color-silver;

  .Tableau-fencerScore {
    border-left: 0;

    &:before {
      content: '';
    }
  }
  .Tableau-fencerName {
    border-right: 0;
  }
}

.Tableau-fencerName {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  font-size: map_get($font-size-primary, xxs);
  font-weight: 500;
  text-overflow: ellipsis;
  text-transform: capitalize;
  padding: $gutter-v/2 $gutter-h/2;
  height: 3.6rem;
  border-right: $border-thin;
  text-align: left;
}

.Tableau-country {
  display: inline-block;
}

.Tableau-fencerFlag {
  max-width: 1.5rem;
  margin-right: $gutter-v/4;
}

.Tableau-fencerNationality {
  font-size: map_get($font-size-secondary, 3xs);
  width: 20%;
}

.Tableau-fencerScore {
  font-family: $font-secondary;
  font-size: map_get($font-size-secondary, 3xs);
  width: 20%;
  padding: $gutter-v/2 0;
  height: 3.6rem;
  border-left: $border-thin;

  &:before {
    content: 'V/';
  }
}

.Tableau-other-results {
  .Tableau-elimination {
    height: auto;
  }

  .Tableau-container > :nth-last-child(2) {
    .Tableau-round:after {
      background-color: $color-background-2;
    }

    .Tableau-fencers {
      margin-right: $gutter-h;
    }
  }

  .Tableau-elimination--visible-0 {
    .Tableau-round:after {
      background-color: transparent;
    }

    .Tableau-fencers {
      margin-right: 0;
    }
  }

}
