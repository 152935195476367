.NavLogo {
  display: block;
  width: 100%;
  max-width: 6rem;
  margin: .9rem 0;
  height: 32px;

  @include break(md) {
    max-width: 9rem;
    margin-top: $gutter-v*2.1;
    margin-bottom: $gutter-v/2;
    transform: translateX(-1.5rem) translateY(0) scale(1);
    height: 44px;
  }

  @include break(lg) {
    max-width: 11rem;
    margin-right: $gutter-h*2;
    height: 53px;
  }

  .NavLogo-letters {
    fill: $color-light;

    @include break(md) {
      fill: $color-foreground-1;
    }
  }

  .NavLogo-words {
    display: none;
    transition: fill .2s;

    @include break(md) {
      display: inline;
      fill: $color-foreground-4;
    }
  }

  .NavLogo-gp {
    display: none;

    @include break(md) {
      display: inline;
      fill: $color-highlight;
    }
  }

  .NavLogo-symbol {
    transition: fill .2s, stop-color .2s;
  }

  .NavLogo-symbol--solid {
    fill: $color-highlight-3;

    @include break(md) {
      fill: $color-foreground-1;
    }
  }

  .NavLogo-symbol--gradientStart {
    stop-color: $color-highlight-2;
  }

  .NavLogo-symbol--gradientEnd {
    stop-color: $color-light;

    @include break(md) {
      stop-color: $color-foreground-1;
    }
  }

  &:hover {
    @include break(md) {
      .NavLogo-symbol {
        fill: $color-highlight;
        stop-color: $color-highlight;
      }
    }
  }
}
