h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  color: $color-foreground-1;
  font-weight: 600;
}
