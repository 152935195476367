.Filter {
  justify-content: space-between;
  align-items: center;
  margin-top: $gutter-v * 2;
  margin-bottom: $gutter-v * 1.5;

  &--showSmall {
    @include break(md) {
      display: none !important;
    }
  }

  &--hideSmall {
    @include breakMax(md) {
      display: none !important;
    }
  }

  &--alwaysShow {
    display: flex;
  }

  @include break(md) {
    display: flex;
    margin-top: $gutter-v * 5;
  }

  &--breakLine {
    flex-wrap: wrap;
    align-items: baseline;
  }

  &--justifyLeft {
    justify-content: left;
  }

  &--document {
    margin-top: 0;
    .Filter-toggles-item {
      margin-left: $gutter-h/2;
    }
  }
}

.Filter-title {
  margin: 0;
  margin-bottom: $gutter-h/2;
  color: $color-foreground-1;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, lg);
  line-height: 1;

  @include break(md) {
    font-size: map-get($font-size-secondary, xl);
    margin-bottom: 0;
  }
}

.Filter-item--last-sm {
  order: 10;

  @include break(md) {
    order: unset;
    margin: 0;
    margin-left: auto;
  }
}

.Filter-toggles {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a.Filter-toggles-item-link {
    transition: color .2s;
    color: $color-foreground-4;

    &.is-active {
      color: $color-foreground-1;
    }
  }

  &-divider,
  &-item {
    margin: 0;
    color: $color-foreground-4;
    line-height: 1;

    @include break(md) {
      padding-left: $gutter-h / 1.5;
    }
  }

  &-divider {
    margin-left: $gutter-h/2;
  }


  &-divider {
    font-size: map-get($font-size-primary, xl);
  }

  &-item {
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, sm);
    cursor: pointer;
    transition: color .2s;

    @include break(md) {
      font-size: map-get($font-size-secondary, md);
    }

    &.is-active,
    &:hover {
      color: $color-foreground-1;

      a.Filter-toggles-item-link {
        color: $color-foreground-1;
      }
    }

    input[type="radio"] {
      width: 0;
      opacity: 0;
    }

    input[type="radio"] + label {
      cursor: pointer;
    }

    input[type="radio"]:checked + label {
      color: $color-foreground-1;
    }

    span {
      pointer-events: none;
    }
  }
}

.Filter-tag {
  font-size: map_get($font-size-primary, lg);
  padding: $gutter-v*0.3 $gutter-v*0.9;
  color: $color-foreground-1;
  font-family: $font-secondary;
  border-radius: $gutter-v;
  margin-left: $gutter-h;
  cursor: pointer;

  &--active {
    background-color: $color-background-3;
  }
}

.Competitions-Filters {
  .Filter {
    @include break(md) {
      margin-top: $gutter-v * 2;
    }
  }
}
