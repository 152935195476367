.Nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  color: $color-foreground-5;
  background-color: $color-light;
  transition: transform .2s;
  z-index: 99;

  @include break(md) {
    &.is-fixed {
      transform: translateY($gutter-v * -1.66);

      .NavLogo-words,
      .NavLogo-symbol {
      }
    }
  }
}
