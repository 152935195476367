.NavDrawer {
  position: fixed;
  top: $nav-height-mobile;
  left: 0;
  width: 100%;
  z-index: 1;
  transform: translateY(-100%);
  transition: transform .3s;

  &.is-open {
    transform: translateY(0);

    .NavDrawer-fade {
      opacity: .66;
      pointer-events: auto;
      cursor: pointer;
    }
  }

  @include break(md) {
    top: auto;
    background-color: $color-background-2;
  }

  @include break(lg) {
    display: none;
  }
}

.NavDrawer-fade {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: $color-dark;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;

  @include break(md) {
    display: none;
  }
}

.NavDrawer-primary,
.NavDrawer-secondary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $color-light;
  text-align: center;

  li {
    display: block;
    border-bottom: $border-thin;
    border-right: $border-thin;
  }

  @include break(md) {
    display: none;
  }
}

.NavDrawer-primary {
  li {
    flex-basis: 50%;
    padding: ($gutter-v * 1.5) 0;
  }

  a.NavDrawer-primary-link {
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xs);
  }
}

.NavDrawer-secondary {
  li {
    flex-basis: 33.33%;
    padding: ($gutter-v / 1.5) 0;
  }

  .NavDrawer-secondary-link {
    font-family: $font-primary;
    font-size: map-get($font-size-primary, xxs);
    font-weight: 500;
    color: $color-foreground-1;

    &:hover {
      color: $color-foreground-2;
    }

    &--dropdown,
    &--login {
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 1rem auto;
      background-color: transparent;
      border: none;
      appearance: none;
      outline: none;
      cursor: pointer;
    }

    &--dropdown {
      padding-right: 1.2rem;
      background-image: url(cdn_image('/img/icon-drop.svg'));
    }

    &--login {
      padding-right: 1.5rem;
      background-image: url(cdn_image('/img/icon-login.svg'));
    }
  }
}

.NavDrawer-social {
  display: flex;
  margin: 0;
  padding: 0;
  background-color: $color-light;
  border-bottom: $border-thin;

  @include break(md) {
    max-width: $container-width-1/1.25;
    margin: auto;
    background-color: $color-background-2;
    border-bottom: none;
  }

  .NavDrawer-social-icon {
    display: block;
    flex-basis: 20%;
    padding: $gutter-v 6%;
    border-right: $border-thin;
    text-align: center;
    height: 56px;

    @include break(sm) {
      padding: $gutter-v 6.5%;
    }

    @include break(md) {
      border: none;
    }
  }
}
