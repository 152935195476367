.HomeVideo {
  padding-top: 0;
  padding-bottom: $gutter-v*2;
  overflow: hidden;

  .MoreLabel {
    padding: map-get($font-size-primary, xxs) 0;
  }

  &-container {
    position: relative;
    border-radius: $border-radius-2;
    overflow: hidden;
  }

  &-cards {
    background-color: $color-light;
  }

  &-card {
    padding: map-get($font-size-primary, xxs);
    cursor: pointer;
    border-bottom: 1px solid $color-foreground-5;
    display: flex;
    align-items: center;
    justify-content: start;

    &.playing {
      .ContentCard-date {
        display: none;
      }

      .HomeVideo-play, .PlayLabel {
        display: initial;
      }
    }
  }

  &-play {
    width: 0;
    height: 0;
    border-top: map-get($font-size-primary, 3xs) solid transparent;
    border-bottom: map-get($font-size-primary, 3xs) solid transparent;
    border-left: map-get($font-size-primary, sm) solid $color-greyscale-4;
    margin-right: map-get($font-size-primary, xxs);
    display: none;
  }

  .PlayLabel {
    display: none;
  }

  @include break(md) {
    padding-bottom: $gutter-v*3;

    .HomeVideo-video {
      display: inline-block;
      width: 60%;
      margin-right: -5px;
      vertical-align: top;
    }

    &-cards {
      display: inline-block;
      width: 40%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &-card {
      height: calc(33% - 1.4rem);
    }

    .ContentCard-title {
      max-height: map-get($font-size-primary, xl);
      margin-bottom: 0;
      overflow: hidden;
    }
  }

  @include break(lg) {
    padding-bottom: $gutter-v*4;

    .ContentCard-title {
      max-height: 4.4rem;
    }
  }
}
