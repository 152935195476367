.Manufacturers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include break(md) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    padding: $gutter-v 0;
  }
}

.Manufacturers-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 40rem;
  margin-bottom: $gutter-v;
  font-family: $font-secondary;
  background-color: $color-light;
  box-shadow: $box-shadow-low;
  border: 1px solid #626262;

  @include break(md) {
    flex-basis: 31.66%;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.Manufacturers-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2.5rem 1.5rem;

  @include break(md) {
    min-height: 10rem;
  }
}

.Manufacturers-card-image {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 14rem;
  margin-bottom: $gutter-v;
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.Manufacturers-card-logo {
  &:hover {
    filter: sepia(0%) hue-rotate(0deg) saturate(100%);
    opacity: .9;
  }
}

.Manufacturers-card-title {
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  color: $color-foreground-1;
  font-weight: 500;
  font-size: map-get($font-size-secondary, sm);
  text-transform: capitalize;
  text-align: center;
}

.Manufacturers-card-role {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, xs);
  text-align: center;
  margin-bottom: $gutter-v;
}

.Manufacturers-card-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, xxs);
  text-align: center;
  margin-bottom: .5rem;
}

.Manufacturers-card-link {
  margin-left: .5rem;
  color: $color-foreground-3;
  transition: color .2s;

  &:hover {
    color: $color-highlight;
  }
}

.Manufacturers-card-address {
  margin-bottom: $gutter-v;
  text-transform: capitalize;
  height: 40px;
}

.Manufacturers-card-contact {
  display: flex;
  justify-content: center;
  justify-self: flex-end;
}

.Manufacturers-card-contact-info {
  flex: 0 0 46px;
  height: 37px;
  margin: 0 15px;

  @include break(md) {
    margin: 0 5px;
  }

  @include break(lg) {
    margin: 0 15px;
  }
}

.Manufacturers-card-contact-website {
  background: url(cdn_image('/img/manufacturer-site.png')) 50% 50% no-repeat;
}

.Manufacturers-card-contact-phone {
  background: url(cdn_image('/img/manufacturer-phone.png')) 50% 50% no-repeat;
}

.Manufacturers-card-contact-fax {
  background: url(cdn_image('/img/manufacturer-fax.png')) 50% 50% no-repeat;
}

.Manufacturers-card-contact-mail {
  background: url(cdn_image('/img/manufacturer-email.png')) 50% 50% no-repeat;
}
