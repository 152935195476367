.RankingAthlete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: $gutter-h;
  margin-right: $gutter-h;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, sm);
  @include break(md) {
    flex-direction: row;
  }
}

.RankingAthlete-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $gutter-v;
  background-color: $color-light;
  box-shadow: 0 1rem 1.8rem 0 rgba($color-shadow, .1);
  border-radius: $border-radius-1;
}

.RankingAthlete-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: $gutter-v ($gutter-v / 2);
  color:  $color-foreground-3;

  &:nth-child(even) {
    background-color: rgba($color-background-3, .5);
  }

  span {
    outline: 1px solid blue;
    flex-basis: 25%;
  }
}

.RankingAthlete-header {
  display: flex;
  align-items: center;
}

.RankingAthlete-headerCol {
  flex-basis: 25%;
  outline: 1px solid red;
}
