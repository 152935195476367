.ResultsPool {
  margin-top: $gutter-v;
  font-size: map-get($font-size-primary, xxs);

  &-grid {
    flex-wrap: nowrap;
  }

  &-pistes {
    height: 100%;
  }

  &-pool {
    border-radius: $border-radius-2;
    border: $border-thin-2;
    overflow: hidden;
    margin-bottom: $gutter-v/2;

    .Table, .AccordionMobile {
      height: 0;
    }

    &--opened {
      .Table, .AccordionMobile {
        height: auto;
      }
    }

    .Table {
      .Grid {
        > div:last-child {
          .Table-cell {
            border-right: none;
          }
        }
      }
    }
  }

  &-titleContainer {
    padding: $gutter-v/2 $gutter-h/2 !important;
    background-color: $color-background-1;
    position: relative;
  }

  &-title {
    font-family: $font-primary;
    font-size: map-get($font-size-primary, md);
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
  }

  &-button {
    position: absolute;
    height: 100%;
    top: 0;
    right: $gutter-v/2;
    cursor: pointer;
    transition: transform 0.3s;
    
    img {
      height: 100%;
      width: 28px;
    }

    &--close {
      transform: rotate(45deg);
    }
  }

  &-referees {
    font-family: $font-primary;
    text-align: center;
    font-weight: 500;
    color: $color-foreground-3;
    font-size: map-get($font-size-primary, xxs);
    margin: 0 $gutter-v*2;
  }

  &-fencer {
    padding-left: $gutter-h/3;
    padding-right: $gutter-h/3;
  }

  &-flagWrapper {
    display: inline;
    margin-right: $gutter-v/8;
    padding: $gutter-v/8;
  }

  &-flag {
    margin-left: .5rem;
    margin-right: .5rem;
    border: $border-thin-2;
  }

  &-score {
    color: $color-foreground-3;
    background-color: $color-light;
  }

  &-victory {
    color: $color-foreground-1;
    background-color: $color-victory;
  }

  &-divider {
    background-color: $color-background-2;
  }

  &-search {
    margin-top: $gutter-v/2;
    margin-bottom: $gutter-v/2;
    position: relative;

    @include break(md) {
      display: inline-block;
    }

    @include break(lg) {
      margin-top: 0;
    }

    input {
      color: $color-foreground-3;
      font-family: $font-primary;
      font-size: map-get($font-size-primary, xs);
      font-weight: 500;
      border: 1px solid $color-background-2;
      border-radius: $border-radius-2;
      height: auto;
      padding-left: 4rem;

      &::placeholder {
        color: $color-foreground-4;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: $gutter-h/2;
      left: $gutter-h/2;
      width: $gutter-h;
      height: $gutter-h;
      background: url(cdn_image('/img/icon-search.svg'));
      background-size: cover;
    }
  }

  &-expandAll {
    text-transform: capitalize;
    margin-left: $gutter-h;
    display: inline-block;
    font-size: map-get($font-size-primary, xs);
    font-weight: 500;
    cursor: pointer;
  }

  &-notFound {
    padding: $gutter-h $gutter-v;
    margin: 0;
    color: $color-foreground-3;
    font-size: map-get($font-size-secondary, 3xs);
    text-align: center;
    border-top: 1px solid $color-background-2;

    @include break(md) {
      border: 1px solid $color-background-2;
    }
  }
}
