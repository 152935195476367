.Carousel {
  user-select: none;

  .slider {
    position: relative;
  }

  .frame {
    position: relative;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    white-space: nowrap;
  }

  .slides {
    display: inline-block;
    padding: 0;
  }

  li {
    position: relative;
    display: inline-block;
  }

  .dots {
    li {
      width: 10px;
      height: 10px;
    }
  }
}
