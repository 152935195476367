.TweetCardGrid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  max-width: $container-width-3;
  margin: $gutter-v/2 auto;

  @include break(md) {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 0 auto $gutter-v*2;
  }
}

.TweetCard {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-grow: 0;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  margin: $gutter-v/2 0;
  font-family: $font-secondary;
  background-color: $color-light;
  border-radius: $border-radius-2;
  box-shadow: $box-shadow-low;
  transition: transform .2s, box-shadow .2s;

  @include break(md) {
    flex-basis: 31.33%;
    flex-grow: 1;
    margin: 0 $gutter-v/2;
  }
}

.TweetCard-frame {
  position: relative;
  width: 100%;
  margin-bottom: $gutter-v/2;
  background-color: $color-background-3;
  overflow: hidden;

  @include break(md) {
    margin-bottom: $gutter-v/1.5;
  }
}

.TweetCard-image {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center top;
  opacity: .9;
}

.TweetCard-type {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: $gutter-v/4;
  color: $color-light;
  font-size: map-get($font-size-secondary, 3xs);
  background-color: $color-highlight-2;

  @include break(sm) {
    font-size: map-get($font-size-secondary, xxs);
  }
}

.TweetCard-profilePicture {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 3rem;
  height: 3rem;
  background-color: $color-foreground-6;
  background-size: cover;
  background-position: center top;
  border-radius: $border-radius-circle;
}

.TweetCard-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: $gutter-v $gutter-h;

  @include break(md) {
    min-height: 18rem;
  }
}

.TweetCard-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: $gutter-v/2 $gutter-h;
  background-color: $color-background-1;
  border-bottom-right-radius: $border-radius-2;
  border-bottom-left-radius: $border-radius-2;
}

.TweetCard-date {
  margin-bottom: .5rem;
  color: $color-foreground-3;
  font-size: map-get($font-size-primary, xxs);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-1;
}

.TweetCard-tweet {
  color: $color-foreground-2;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, xs);
  font-weight: 500;
  line-height: 1.5;
}

.TweetCard-authorMeta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: $gutter-v;
  line-height: 1.5;
}

.TweetCard-authorName {
  color: $color-foreground-2;
  font-size: map-get($font-size-primary, xs);
  font-weight: 600;
}
