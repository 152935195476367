.RecentResults {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.RecentResults-competition {
  flex-basis: 100%;

  &:nth-of-type(2) {
    display: none;
  }

  @include break(md) {
    flex-basis: 47.5%;

    &:nth-of-type(2) {
      display: block;
    }
  }
}

.RecentResults-date {
  margin-top: 0;
  margin-bottom: $gutter-v / 3;
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, xxs);
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;
}

.RecentResults-title {
  margin-top: 0;
  margin-bottom: $gutter-v / 1.5;
  font-size: map-get($font-size-secondary, sm);
  line-height: 1.3;
  text-transform: capitalize;
}

.RecentResults-dropdown {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: ($gutter-v / 1.5) ($gutter-h / 1.5);
  background-color: transparent;
  background-image: url(cdn_image('/img/icon-drop.svg'));
  background-repeat: no-repeat;
  background-position: center right ($gutter-h / 1.5);
  background-size: 1rem auto;
  border: $border-thin;
  appearance: none;
  color: $color-foreground-3;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, xs);
  font-weight: 500;

  &:focus {
    outline: none;
    color: $color-foreground-2;
    background-color: $color-background-1;
  }
}

.RecentResults-list {
  margin: ($gutter-v * 1.5) 0;
  padding: 0;
  list-style: none;
  color: $color-foreground-1;
  font-size: map-get($font-size-primary, xs);
}

.RecentResults-list-item {
  position: relative;
  margin-top: $gutter-v;
  padding-bottom: $gutter-v;
  padding-left: $gutter-h * 1.5;
  border-bottom: $border-thin;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  &::before {
    content: attr(data-rank);
    position: absolute;
    display: block;
    left: 0;
    color: $color-foreground-3;
  }
}

.RecentResults-list-item--team {
  padding-left: $gutter-h*2.5;

  .RecentResults-list-country {
    left: $gutter-h*1.2;
    right: auto;
  }

  .RecentResults-list-countryName {
    display: none;
  }
}

.RecentResults-list-country {
  position: absolute;
  right: 0;
  top: .2rem;
  color: $color-foreground-3;
  text-transform: uppercase;
  font-size: map-get($font-size-primary, xxs);
  line-height: 1;

  span {
    margin-left: .6rem;
  }
}

