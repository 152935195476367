.FieHistory {
  color: $color-foreground-2;

  ol {
    background-color: $color-light;
    -webkit-margin-before: 0;
    -webkit-padding-start: 0;
    padding: $gutter-v/2;
    border-radius: $border-radius-2;
    box-shadow: $box-shadow-low;

    @include break(sm) {
      padding: $gutter-v/1.33;
    }

    > ul:last-of-type {
      margin-bottom: 0;
    }
  }

  ol > li {
    font-weight: 500;

    ul {
      font-weight: 400;
    }
  }
}