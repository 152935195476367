.Footer {
  padding: ($gutter-v * 1.5) 0;
  background-color: $color-dark;

  @include break(md) {
    padding: ($gutter-v * 2) 0;
  }
}

.Footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: $gutter-v / 1.5;
  padding: 0;
  line-height: 1.4;
  list-style: none;
}

.Footer-menu-link {
  display: block;
  margin-bottom: $gutter-v / 3;
  margin-right: $gutter-h / 2;
  margin-left: $gutter-h / 2;
  color: $color-light;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, 3xs);
  transition: color .2s;

  &:hover {
    color: $color-highlight;
  }

  @include break(sm) {
    font-size: map-get($font-size-secondary, xxs);
  }

  @include break(md) {
    font-size: map-get($font-size-secondary, xs);
    margin-right: $gutter-h / 1.5;
    margin-left: $gutter-h / 1.5;
  }
}

.Footer-copyright {
  max-width: 88rem;
  margin: 0;
  padding: 0;
  color: $color-greyscale-4;
  font-size: map-get($font-size-primary, 3xs);
  font-weight: 300;
  line-height: 1.5;

  @include break(sm) {
    font-size: map-get($font-size-primary, xxs);
  }
}
