.Instagram {
  margin-bottom: $gutter-v * 1.5;

  @include break(sm) {
    margin-bottom: $gutter-v * 2;
  }

  @include break(md) {
    margin-bottom: $gutter-v * 3;
  }
}

.Instagram-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $gutter-v*1.5;
  padding: 0;

  @include break(md) {
    margin-bottom: $gutter-v*2;
    padding-left: $gutter-h*2;
    padding-right: $gutter-h*2;
  }
}

.Instagram-header-title {
  margin: 0 auto;
  font-size: map-get($font-size-secondary, lg);
  text-align: center;

  @include break(md) {
    font-size: map-get($font-size-secondary, xl);
    text-align: center;

    &::after {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.Instagram-cards {
  a:first-child {
    .Instagram-frame {
      margin-left: 0;
    }
  }

  a:nth-child(3) {
    .Instagram-frame {
      margin-right: 0;
    }
  }

  a:nth-child(4), a:nth-child(5) {
    display: none;
  }


  .Instagram-frame {
    display: inline-block;
    width: calc(33% - 5px - 0.5rem);
    margin: 0 0.5rem;

    .Instagram-image {
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      border-radius: $border-radius-1;
    }

    &--whiteBorder {
      border-radius: $border-radius-1;
      background-color: $color-light;
      padding: $gutter-v*0.4;

      .Instagram-image {
        border-radius: 0;
      }
    }
  }

  @include break(md) {
    .Instagram-frame {
      width: calc(24% - 5px - 0.8rem);
      margin: 0 1rem;
    }

    a:nth-child(3) {
      .Instagram-frame {
        margin-right: 1rem;
      }
    }

    a:nth-child(4) {
      display: inline;

      .Instagram-frame {
        margin-right: 0;
      }
    }
  }

  @include break(lg) {
    .Instagram-frame {
      width: calc(20% - 5px - 1.6rem);
      margin: 0 1rem;
    }

    a:nth-child(4) {
      .Instagram-frame {
        margin-right: 1rem;
      }
    }

    a:nth-child(5) {
      display: inline;

      .Instagram-frame {
        margin-right: 0;
      }
    }
  }
}

