$control-desktop-height: 4rem;

.VideoPlayer {
  padding: 0;
  background-color: $color-dark;

  @include break(md) {
    padding-top: $gutter-v * 2;
  }

  &--preview {
    background-position: center;
    background-size: cover;
    padding-top: 56%;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 4rem);
      left: calc(50% - 3rem);
      width: 0;
      height: 0;
      border-top: 4rem solid transparent;
      border-bottom: 4rem solid transparent;
      border-left: 6rem solid $color-light;
      z-index: 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: transparentize($color-dark, 0.8);
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.VideoPlayer-container {
  padding: 0;

  @include break(md) {
    padding-left: $gutter-h * 2;
    padding-right: $gutter-h * 2;
  }

  &--twoPerRow {
    .VideoPlayer {
      width: 100%;
      max-width: 56rem;
      padding-top: 0;
      margin-bottom: 0;

      &--preview {
        padding-top: 56%;
        cursor: pointer;
      }

      @include break(lg) {
        width: calc(50% - #{$gutter-h*0.4});
        max-width: calc(50% - #{$gutter-h*0.4});
      }
    }

    > div:nth-child(odd) {
      margin-bottom: $gutter-h*0.4;
    }

    @include break(lg) {
      > div:nth-child(odd) {
        margin-right: $gutter-h*0.4;
        margin-bottom: 0;
      }

      > div:nth-child(even) {
        margin-left: $gutter-h*0.4;
      }
    }
  }
}

.VideoPlayer {
  .jw-background-color {
    background-color: $color-background-1;

    &.jw-slider-horizontal {
      background-color: $color-foreground-3;
    }
  }

  .jw-display-controls {
    .jw-icon {
      color: $color-foreground-3 !important;
    }
  }

  .jw-controlbar {
    .jw-button-color, .jw-text {
      color: $color-foreground-3;

      &:hover {
        color: $color-foreground-2;
      }
    }
  }

  .jw-slider-horizontal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

  }

  .jw-slider-vertical, .jw-slider-horizontal {
    .jw-progress {
      background-color: $color-highlight;
    }
    .jw-rail {
      background-color: $color-foreground-3;
    }
  }

  .jwplayer:not(.jw-flag-touch):not(.jw-error):not(.jw-state-error):not(.jw-state-buffering) .jw-display-icon-container:hover,
  .jwplayer:not(.jw-flag-touch):not(.jw-error):not(.jw-state-error):not(.jw-state-buffering) .jw-media:hover~.jw-controls .jw-display-icon-display{
    background-color: $color-background-1 !important;
  }

  .jw-icon-hd {
    display: none;
  }

  &-container {
    background-position: center;
    background-size: cover;
  }
}

// JW player for mobile
@include breakMax(md) {
  .VideoPlayer {
    .jw-slider-container,
    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer .jw-slider-time .jw-slider-container,
    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer .jw-controlbar-center-group,
    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer .jw-slider-time {
      height: 0;
    }

    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer:not(.jw-flag-ads) .jw-controlbar,
    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer:not(.jw-flag-live) .jw-controlbar {
      height: 4.5rem;
      padding: 0;
    }

    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer .jw-slider-time .jw-buffer,
    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer .jw-slider-time .jw-progress,
    .jw-flag-time-slider-above:not(.jw-flag-ads-googleima).jwplayer .jw-slider-time .jw-rail {
      height: 0.4rem;
    }

    .jw-controlbar.jw-background-color {
      background: $color-background-1 !important;
    }
  }
}

// JW player for desktop
@include break(md) {
  .VideoPlayer {

    .jwplayer:hover {
      .jw-controlbar {
        visibility: visible !important;
        pointer-events: auto !important;
        opacity: 1 !important;
      }
    }

    .jw-controlbar {
      display: flex;
    }

    .jw-controlbar-center-group {
      position: static;
      flex: 0;
      padding: 0;
    }

    .jw-controlbar-right-group {
      display: flex;
      flex: 1;
      align-items: center;

      .jw-text-duration {
        position: relative;
        margin-right: auto;

        &::before {
          position: absolute;
          left: -0.25rem;
          content: '/'
        }
      }
    }
  }
}

.VideoPlayer-Description {
  &-Title {
    margin-bottom: 0;
  }

  &-Date {
    margin-top: 0;
    margin-bottom: $gutter-v;
    color: $color-foreground-3;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
  }

  &-Excerpt {
    font-size: map_get($font-size-secondary, sm);
    margin-bottom: 2*$gutter-v;
    margin-top: 0;
    color: $color-foreground-2;
    font-family: $font-primary;
    font-size: map-get($font-size-primary, xs);
    line-height: 1.4;
  }
}

.jwplayer.jw-flag-user-inactive:not(.jw-flag-media-audio).jw-state-playing .jw-media {
  pointer-events: none !important;
}

.js-video-player-container {
  max-width: 720px;
  margin: auto;
}
