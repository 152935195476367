.Confederations {
  display: flex;
  flex-direction: column;
}

.Confederations-grid {
  margin-bottom: $gutter-v;
}

.Confederations-form {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: $gutter-v;
  background: #182d70 url(cdn_image('/img/BCK1.jpg')) 50% 30%;

  .Form-dropdown.Form-dropdown--capitalize {
    width: 50%;
    margin: 0;
    max-width: none;
    border: none;
    background-color: transparent;
    border-radius: 0;
    color: white;
    text-align: center;

    &:first-child {
      border-right: 1px solid white;
    }

    option {
      background: #182d70;
      text-align: left;
    }
  }
}

.Confederations-zoneCard {
  width: 100%;
  max-width: 64rem;
  margin-bottom: $gutter-v;

  @include break(md) {
    flex-basis: 48.5%;
    max-width: 100%;
    margin-bottom: $gutter-v*1.5;
  }
}

.Confederations-zoneCard-inside {
  position: relative;
  justify-content: flex-start;
  width: 100%;
  padding: $gutter-v $gutter-h;
  background-color: #d0d1d3;
  background: #d0d1d3 url(cdn_image('/img/BCK2.jpg')) 50% 30%;
  border-radius: $border-radius-1;
  box-shadow: $box-shadow-low;
  transition: transform .2s, box-shadow .2s;

  @include break(md) {
    display: flex;
    padding-top: $gutter-v*1.5;
    padding-bottom: $gutter-v*1.5;
    padding-left: $gutter-h*1.5;
    padding-right: $gutter-h;
  }
}

.Confederations-zoneCard-frame {
  position: relative;
  width: 100%;
  margin-right: $gutter-h;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @include break(md) {
    max-width: 30rem;
  }
}

.Confederations-zoneCard-image {
  width: 100%;
  padding-top: 20rem;
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.Confederations-zoneCard-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Confederations-zoneCard-title {
  margin-top: 0;
  margin-bottom: $gutter-v/3;
  color: $color-foreground-1;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, sm);
  font-weight: 600;
  line-height: 1.2;
  text-transform: none;
}

.Confederations-zoneCard-type {
  margin: 0;
  color: $color-foreground-3;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xs);
}

.Confederations-zoneCard-address {
  p {
    margin: 0;
  }
}

.Confederations-zoneCard-date,
.Confederations-zoneCard-event {
  display: inline-block;
  margin-bottom: $gutter-v/2;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, 3xs);
  color: $color-foreground-3;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-1;
}

.Confederations-zoneCard-date {
  margin-right: $gutter-h/1.75;
  padding-right: $gutter-h/1.75;
  border-right: 2px solid $color-foreground-5;
}

.Confederations-zoneCard-header {
  margin-bottom: $gutter-v;
  background: #182d70 url(cdn_image('/img/BCK1.jpg')) 50% 36%;
  color: white;
  text-align: center;
  padding: 1.3rem 0;
  font-size: 18px;
  height: 50px;
  text-transform: uppercase;
}

.Confederations-context {
  display: flex;
  flex-direction: column;
}

.Confederations-continents {
  display: none;

  @include break(md) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: $gutter-v/2;
    justify-content: space-between;

    .MemberAdmin-card {
      flex-basis: 18%;
      cursor: pointer;

      .MemberAdmin-card-image {
        width: 7rem;
        height: 7rem;
        margin-bottom: 0;
      }
    }
  }

  @include break(lg) {
    display: block;
    width: 100%;
    height: 349px;
    background: url(cdn_image('/img/confederations-map.png')) 50% 50%;
    background-size: cover;
    position: relative;

    .MemberAdmin-card {
      width: 60px;
      padding: 5px;
      border: 1px solid #a7a7a7;
      box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.4);
      position: absolute;

      &--cee,
      &--europe {
        top: 75px;
        left: 380px;
      }

      &--cae,
      &--africa {
        top: 185px;
        left: 360px;
      }

      &--fca,
      &--asia {
        top: 105px;
        right: 195px;
      }

      &--cpe,
      &--america {
        bottom: 80px;
        left: 155px;
      }

      &--ofc,
      &--oceania {
        bottom: 20px;
        right: 135px;
      }

      .MemberAdmin-card-content {
        padding: 0;
        min-height: 70px;
        flex-direction: column-reverse;
      }

      .MemberAdmin-card-title {
        margin-bottom: 0;
        font-size: 1.4rem;
      }

      .MemberAdmin-card-image {
        width: 5rem;
        height: 5rem;
        margin-bottom: 0;

      }
    }
  }
}
