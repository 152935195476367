$minHeight: 12rem;
$labelCololor: #9195BA;

.AthleteCompetitionCard {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xs);

  @include break(md) {
    flex-basis: 30%;
    flex-grow: 0;
  }
}

.AthleteCompetitionCard-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $color-shadow;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 1rem;
  }
}

.AthleteCompetitionCard-date {
  color: $color-foreground-2;
}

.AthleteCompetitionCard-cell {
  margin: $gutter-h/4 .5rem;
  color: $color-foreground-1;
  font-weight: 300;
  text-transform: capitalize;
}
