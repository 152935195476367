.CompetitionCard {
  width: 100%;
  max-width: 64rem;
  margin-bottom: $gutter-v;

  @include break(md) {
    flex-basis: 48.5%;
    max-width: 100%;
    margin-bottom: $gutter-v*1.5;
  }
}

.CompetitionCard-inside {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: $gutter-v $gutter-h;
  background-color: $color-light;
  border-radius: $border-radius-1;
  box-shadow: $box-shadow-low;
  transition: transform .2s, box-shadow .2s;

  @include break(sm) {
    padding-top: $gutter-v*1.5;
    padding-bottom: $gutter-v*1.5;
    padding-left: $gutter-h*1.25;
    padding-right: $gutter-h;
  }

  &:hover {
    box-shadow: $box-shadow-soft;
    transform: scale(1.015);
  }
}

.CompetitionCard-frame {
  position: relative;
  width: 100%;
  max-width: 6.2rem;
  margin-right: $gutter-h;
  background-color: $color-background-3;
  border-radius: $border-radius-circle;
  overflow: hidden;
}

.CompetitionCard-image {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  opacity: .8;
  border-radius: $border-radius-circle;
}

.CompetitionCard-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.CompetitionCard-title {
  margin-top: 0;
  margin-bottom: $gutter-v/3;
  color: $color-foreground-1;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, sm);
  font-weight: 600;
  line-height: 1.2;
  text-transform: none;
}

.CompetitionCard-type {
  margin: 0;
  color: $color-foreground-3;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xs);
  text-transform: capitalize;
}

.CompetitionCard-date,
.CompetitionCard-event {
  display: inline-block;
  margin-bottom: $gutter-v/2;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, 3xs);
  color: $color-foreground-3;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-1;
}

.CompetitionCard-date {
  margin-right: $gutter-h/1.75;
  padding-right: $gutter-h/1.75;
  border-right: 2px solid $color-foreground-5;
}
