/* https://github.com/lipis/flag-icon-css */

.Flag-icon {
  @extend .Flag-icon-background;
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  line-height: 1em;
  &:before {
    content: '\00a0';
  }
  &.Flag-icon--squared {
    width: 1em;
  }
}

.Flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

@mixin flag-icon($country) {
  .Flag-icon--#{$country} {
    background-image: url(cdn_image('#{$flag-icon-css-path}#{$flag-icon-rect-path}/#{$country}.svg'));
    &.Flag-icon--squared {
      background-image: url(cdn_image('#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.svg'));
    }
  }
}
