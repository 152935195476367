.Form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  height: 100%;

  &--full {
    display: block;
    width: 100%;
  }

  &--footer {
    margin-top: 0;
    color: $color-foreground-4;
    line-height: 1.5;
    font-weight: 500;
    text-transform: uppercase;
    font-family: $font-secondary;
  }
}

.Form--column {
  flex-direction: column;
}

.Form-group {
  position: relative;
  height: 100%;
}

.Form-error {
  color: $color-warning;
}

.Form-success {
  color: $color-success;
}

input.Form-text {
  display: block;
  color: $color-foreground-1;
  border-bottom: 1px solid $color-shadow;
  padding: 1rem;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  appearance: 0;
  padding-left: $gutter-h * 2;

  &:focus {
    border-bottom: 1px solid $color-shadow;
    outline: none;
    padding-left: $gutter-h * 2;
  }

  &--dark {
    color: $color-light;
    background: $color-foreground-1;
  }

  &--border-top-radius {
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
  }

  &--border-bottom-radius {
    border-bottom-left-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }

  &--email {
    padding: $gutter-v $gutter-h;
    width: 100%;
    margin: ($gutter-v / 2) 0;
    font-size: map-get($font-size-secondary, sm);
    color: $color-foreground-2;
    text-align: center;
    background-color: $color-background-3;
    border: none;

    &::placeholder {
      color: $color-foreground-2;
    }

    &:focus {
      background-color: $color-background-2;
      outline: none;
      box-shadow: none;
    }
  }
}

input[type="search"].Form-text-search {
  margin: ($gutter-v / 2) 0;
  color: $color-foreground-2;
  font-size: map-get($font-size-secondary, xs) / 1.2;
  padding-left: $gutter-h * 2.5;
  background-color: $color-background-1;
  background-image: url(cdn_image('/img/icon-search.svg'));
  background-position: ($gutter-h / 1.25) center;
  background-size: 2rem;
  background-repeat: no-repeat;
  border-bottom: 0;
  border-radius: $border-radius-1;

  &::placeholder {
    color: $color-foreground-2;
  }

  &:focus {
    background-color: $color-background-2;
    outline: none;
    box-shadow: none;
  }
}

.Form-icon {
  position: absolute;
  top: $gutter-v;
  left: $gutter-h / 2;
  content: '';
  width: $gutter-h;
  height: $gutter-v;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(cdn_image('/img/icon-lightning-blue.svg'));
}

.Form-log {
  height: 0;
  color: $color-highlight;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  transition: opacity .2s;

  &.is-active {
    height: auto;
    padding: $gutter-v $gutter-h;
    visibility: visible;
    opacity: 1;
  }
}

.Form-selectBox {
  position: relative;
  margin: ($gutter-v / 2) 0;
  background-color: $color-light;
  border: 1px solid $color-foreground-5;
  border-radius: $border-radius-1;

  select {
    position: absolute;
    left: 0;
    top: 6px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }
}

.Form-selectBox.hiddenElement {
  display: none;
}

.Form-selectOption {
  display: flex;
  align-items: center;
  align-content: space-around;
  justify-content: space-around;
  padding: ($gutter-v / 2) ($gutter-h / 2);
  color: $color-foreground-1;
  font-family: $font-primary;
  font-size: map-get($font-size-secondary, xxs) / 1.2;
  font-weight: 500;
  letter-spacing: 0;

  span {
    flex-basis: 80%;
    text-transform: capitalize;
  }
}

.Form-selectOption--icon {

  &::before,
  &::after {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.Form-selectOption--iconBefore-user {
  &::before {
    width: $gutter-h / 1.5;
    height: $gutter-v / 1.5;
    background-image: url(cdn_image('/img/icon-user.svg'));
  }
}

.Form-selectOption--iconAfter-drop {
  &::after {
    width: $gutter-h / 1.5;
    height: $gutter-v / 1.5;
    background-image: url(cdn_image('/img/icon-drop.svg'));
  }
}

.Form-selectBox--noBorder {
  border: 0;
}

.Form-dropdown {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: ($gutter-v / 1.5) ($gutter-h / 1.5);
  padding-right: $gutter-h*1.5;
  background-color: transparent;
  background-image: url(cdn_image('/img/icon-drop.svg'));
  background-repeat: no-repeat;
  background-position: center right ($gutter-h / 1.5);
  background-size: 1rem auto;
  border: $border-thin;
  appearance: none;
  color: $color-foreground-3;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, xs);
  font-weight: 500;
  cursor: pointer;

  &--white {
    background-color: $color-light;
  }

  &:focus {
    outline: none;
    color: $color-foreground-2;
    background-color: $color-background-1;
  }

  &--searchBar {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $gutter-h/4;
    padding-right: $gutter-h * 1.5;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xxs);
    border: none;

    &:focus {
      color: $color-foreground-1;
      background-color: transparent;
    }
  }

  &--noBorder {
    height: 100%;
    border-radius: 0;
    border: 0;

    &:focus {
      background-color: $color-light;
    }
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--clubs {
    padding: $gutter-v $gutter-h/1.5;
    color: $color-foreground-5;
    background-color: $color-foreground-1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      color: $color-foreground-5;
      background-color: $color-foreground-1;
    }
  }

  &--md {
    width: 100%;
    padding: $gutter-v/2 $gutter-h;

    @include break(md) {
      width: 14rem;
    }
  }

  &--sm {
    padding: ($gutter-v / 2) ($gutter-h / 4);
    padding-right: $gutter-h/1.25;
    background-position: center right ($gutter-h / 4);
    background-size: 1rem auto;
    font-size: map-get($font-size-primary, xxs);

    @include break(md) {
      padding: ($gutter-v / 1.5) ($gutter-h / 1.5);
      padding-right: $gutter-h*1.5;
      background-position: center right ($gutter-h / 1.5);
      background-size: 1rem auto;
      font-size: map-get($font-size-primary, xs);
    }
  }

  &--floatRight {
    display: inline-block;
    float: right;
    margin-top: -$gutter-h/2;
    width: auto;
  }
}

.Form-action--large {
  margin-top: 3rem;
  margin-bottom: 2.5rem;
}

.Form-loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}

.Form-loading {
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  right: 0;
  width: $gutter-h*2;
  height: $gutter-h*2;
  margin: auto;
  margin-top: -$gutter-h*2;
  border: $gutter-v/3 solid $color-highlight;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite rotate-forever;
  pointer-events: none;
  z-index: 999;
}
