.MemberAdmin {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include break(md) {
    flex-direction: row;
    justify-content: space-between;
    padding: $gutter-v 0;
  }
}

.MemberAdmin-heading {
  display: block;
  width: 100%;
  padding: $gutter-v 0;
}

.MemberAdmin-card {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 40rem;
  margin-bottom: $gutter-v;
  font-family: $font-secondary;
  background-color: $color-light;
  border-radius: $border-radius-2;

  @include break(md) {
    flex-basis: 31.66%;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.MemberAdmin-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: $gutter-v*1.5 $gutter-h;
  background-color: $color-light;
  border-radius: inherit;
  box-shadow: $box-shadow-low;

  @include break(md) {
    min-height: 10rem;
  }
}

.MemberAdmin-card-image {
  display: flex;
  justify-content: flex-end;
  width: 10rem;
  height: 10rem;
  margin-bottom: $gutter-v*2;
  background-color: $color-background-2;
  background-size: cover;
  border-radius: $border-radius-circle;
  filter: brightness(95%);
}

.MemberAdmin-card-flag {
  align-self: flex-end;
  max-width: 1.5rem;
  margin-right: $gutter-h/2;
}

.MemberAdmin-card-subHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $gutter-v*2;
  color: $color-foreground-3;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, sm);
  font-weight: 300;
}

.MemberAdmin-card-countryName {
  color: $color-foreground-3;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xxs);
  line-height: 1;
  text-transform: capitalize;
}

.MemberAdmin-card-title {
  margin-top: 0;
  margin-bottom: $gutter-v/2.5;
  color: $color-foreground-1;
  font-weight: 500;
  font-size: map-get($font-size-secondary, sm);
  text-transform: capitalize;
  text-align: center;
}

.MemberAdmin-card-role {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, xs);
  text-align: center;
  margin-bottom: $gutter-v;
  max-width: 100%;
}

.MemberAdmin-card-bio,
.MemberAdmin-card-phone,
.MemberAdmin-card-email {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, xxs);
  text-align: center;
  margin-bottom: .5rem;
  max-width: 100%;
}

.MemberAdmin-card-link {
  margin-left: .5rem;
  color: $color-foreground-3;
  transition: color .2s;
}

a.MemberAdmin-card-link:hover {
  color: $color-highlight;
}
