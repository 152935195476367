.ResultItem {
  display: flex;
  flex-direction: row;
  padding: $gutter-v 0;
}

.ResultItem-pin {
  position: relative;
  flex-basis: 20%;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xxs);
  text-align: center;
  line-height: 1.6;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3rem;
    top: 0;
    left: 0;
    background-image: url(cdn_image('/img/icon-pin.svg'));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    opacity: .5;
  }
}

.ResultItem-info {
  display: flex;
  flex-direction: column;
  flex-basis: 70%;

  &-title {
    padding-bottom: $gutter-v/2;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xxs);
  }

  &-address,
  &-tel,
  &-url {
    padding-bottom: $gutter-v/4;
  }
}

.ResultItem-contentInfoWindow {
  color: $color-foreground-1;
  font-weight: 600;
  text-transform: capitalize;
}
