.PressRegister {

  &-title {
    margin: $gutter-v 0;
  }

  &-form {
    margin-bottom: $gutter-v * 2;
  }

  &-error {
    font-size: 1.6rem;
    color: red;
    padding: 10px 20px;
    background: white;
    margin-top: 20px;
  }

  &-success {
    font-size: 1.6rem;
    color: $color-foreground-1;
    padding: 10px 20px;
    background: white;
    margin-top: 20px;
  }

  &-file {
    @include break(md) {
      margin: 0 10px;
    }
    display: flex;
    align-items: center;
    flex: 1 1 0;
    margin-bottom: 20px;
    background: white;
    padding-left: 10px;
  }

  &-formLine {
    @include break(md) {
      display: flex;
      margin-bottom: $gutter-v;
      align-items: stretch;
    }
  }

  &-inputField {
    margin: $gutter-v 0;
    display: block;
    width: 100%;
    padding: $gutter-v / 2;
    min-height: 5.6rem;
    background-color: $color-light;
    font-size: 1.8rem;
    font-weight: 500;
    appearance: none;
    border: none;
    border-radius: 0;
    transition: background-color .2s;

    &::placeholder {
      color: $color-foreground-3;
    }

    &:focus {
      outline: none;
      color: $color-foreground-1;
      background-color: $color-background-1;
    }

    @include break(md) {
      flex: 1 1 0;
      margin: 0 $gutter-h / 2;
    }
  }

  & &-inputField--aligned-no {
    padding-right: 5rem;
  }

  &-dropdown {
    color: $color-foreground-3;
    padding-right: $gutter-h*2.5;
    background-image: url(cdn_image('/img/icon-drop.svg'));
    background-position: right ($gutter-h / 1.25) center;
    background-size: 1.2rem auto;
    background-repeat: no-repeat;

    &:focus {
      outline: none;
      color: $color-foreground-1;
    }
  }

  &-label {
    margin-right: 15px;
    font-size: 1.6rem;
    color: $color-foreground-3;
  }

  &-indicators {
    display: flex;
    align-items: center;

    label {
      display: flex;
      align-items: center;
      font-weight: 400;
      margin-bottom: 0;
      cursor: pointer;
      padding-left: 0;
      margin-right: 15px;
      font-size: 1.6rem;
      color: $color-foreground-3;
      
      &:last-child {
        margin-right: 0;
      }
    }

    input[type="radio"] {
      position: absolute;
      z-index: -10;
      width: 0;
      height: 0;

      & + i {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #adadac;
        position: relative;
        border-radius: 5px;
        margin-right: 6px;

        &:before {
          content: "";
          position: absolute;
          top: 1px;
          bottom: 1px;
          left: 1px;
          right: 1px;
          background: url(cdn_image('/img/icons/check2.png')) no-repeat center;
          background-size: 12px 9px;
          background-color: #fff;
          border-radius: 5px;
          transform: scale(0);
          transition: 200ms;
        }
      }

      &:checked {

        & + i {
          border: 1px solid $color-foreground-3;

          &:before {
            transform: scale(1, 1);
          }
        }
      }
    }

  }
}
