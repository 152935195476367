/**
 * This is a table
 *
 * Example HTML:
 *
 * <div class="Table">
 *   ...
 * </div>
 */

 $tableMaxWidth: 70rem;

.Table {
  background-color: $color-light;
  font-family: $font-secondary;
  border-radius: $border-radius-2;

  @include break(sm) {
    padding: ($gutter-v / 1.5) ($gutter-h / 1.5);
  }
}

.Table--fixed {
  width: 100%;
  table-layout: fixed;
}

.Table--collapseBorder {
  border-collapse: collapse;
}

.Table-noSpacing {
  border-spacing: 0;
}

.Table--marginBottom {
  margin-bottom: $gutter-v*2;
}

.Table--noRadius {
  border-radius: 0;
}

.Table--noPad {
  @include break(sm) {
    padding: 0;
  }
}

.Table--shadow {
  box-shadow: $box-shadow-low;
}

.Table--narrow {
  max-width: $tableMaxWidth;
  margin: 0 auto;
}

.Table-head {
  border-top-left-radius: $border-radius-1;
  border-top-right-radius: $border-radius-1;
}

.Table-head--colorLight {
  color: $color-foreground-5;
  background-color: transparent;
}

.Table-head--colorDark {
  color: $color-foreground-1;
  background-color: transparent;
}

.Table-head--backgroundLight {
  color: $color-foreground-1;
  background-color: $color-foreground-3;
}

.Table-head--backgroundDark {
  color: $color-foreground-3;
  background-color: $color-foreground-1;
}

.Table-head--bold {
  font-weight: 600;
}

.Table-body {
}

.Table-row {
  position: relative;
}

.Table-row--stripes {

  &:nth-child(odd) {
    background-color: $color-foreground-6;
  }
}

.Table-row--hover {
  &:hover {
    background-color: $color-background-1;
  }
}

.Table-head--borderBottom,
.Table-row--borderBottom {
  border-bottom: $border-thin-2;

  &:last-of-type {
    border-bottom: none;
  }

  &:only-of-type {
    border-bottom: $border-thin-2;
  }
}

.Table-row--header {
  background-color: $color-background-1;
}

.Table-row--hide {
  opacity: 0.3;
}

.Table-cell {
  padding: $gutter-v/2 $gutter-h/2;
  text-align: center;

  @include break(md) {
    padding: $gutter-v $gutter-h;
    line-height: 1.5;
  }
}

.Table-cell--borderRight {
  height: 100%;
  border-right: 0.1px solid $color-background-2;
}

.Table-cell--boldBorderLeft {
  border-left: $gutter-h*0.2 solid $color-background-2;
}

.Table-cell--smallPadding {
  padding: $gutter-v/2 $gutter-h/2;

  @include break(md) {
    padding: $gutter-v/1.25 $gutter-h/2;
  }
}

.Table-cell--show-sm {
  display: none;

  @include break(sm) {
    display: table-cell;
  }
}

.Table-cell--hide-md {
  display: table-cell;

  @include break(md) {
    display: none;
  }
}

.Table-cell--show-md {
  display: none;

  @include break(md) {
    display: table-cell;
  }
}

.Table-cell--show-lg {
  display: none;

  @include break(lg) {
    display: table-cell;
  }
}

.Table-cell--alignLeft {
  text-align: left;
}

.Table-cell--alignRight {
  text-align: right;
}

.Table-cell--centered {
  text-align: center;
}

.Table-cell--smallerText {
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, sm);
  text-transform: capitalize;
  vertical-align: top;
}

.Table-cell--noWrap {
  white-space: nowrap;
}

.Table-cell--noPadVertical {
  padding-top: 0;
  padding-bottom: 0;
}

.Table-cell--noPadHorizontal {
  padding-left: 0;
  padding-right: 0;
}

.Table-cell--noPadRight {
  padding-right: 0;
}

.Table-cell--noPadLeft {
  padding-left: 0;
}

.Table-cell--smallPadTop {
  padding-top: $gutter-v*0.4;
}

.Table-cell--lg {
  @include break(md) {
    width: 16rem;
  }

  @include break(lg) {
    width: 18rem;
  }
}

.Table-cell--spaceAround {
  justify-content: space-around;
}

.Table-cell--spaceBetween {
  justify-content: space-between;
}

.Table-cell--justifyStart {
  justify-content: flex-start;
}

.Table-cell--justifyEnd {
  justify-content: flex-end;
}

.Table-title {
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, xxs);

  &--smaller {
    font-size: map-get($font-size-secondary, 3xs);

    @include break(md) {
      font-size: map-get($font-size-secondary, xxs);
    }
  }
}

.Table-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, xxs);
  text-align: left;
  text-transform: capitalize;

  &--column {
    flex-direction: column;
  }
}

.Table-title--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Table-number {
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, xxs);
  font-weight: 500;
  text-align: left;
}

.Table-hand {
  text-transform: uppercase;
  text-align: center;
}

.Table-country {
  display: inline-flex;
  padding: $gutter-v/4 $gutter-h/1.67;
  background-color: $color-background-2;
  /* border-radius: $border-radius-2*10; */

  &--marginBottom {
    margin-bottom: $gutter-h/2;
  }
}

.Table-country-flag {
  min-width: 1.25rem;
  max-width: 1.5rem;
}

.Table-country-name {
  display: none;
  color: $color-foreground-2;
  font-size: map-get($font-size-secondary, 3xs);
  text-transform: capitalize;

  &:only-child {
    display: block;
  }

  @include break(md) {
    display: block;
    margin-left: $gutter-h/3;

    &:only-child {
      margin-left: 0;
    }
  }
}

.Table-anchor {
  flex: 1 0 100%;
}

.Table-date {
  font-size: map-get($font-size-secondary, xxs);
  font-weight: 300;
  color: $color-foreground-3;
}
