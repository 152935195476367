.Subtabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Subtabs-nav {
  width: 100%;
  margin: 0 auto;
  width: 100%;
}

.Subtabs-nav--results {
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, rgba(149,149,149,0.2) 0%,rgba(13,13,13,0.3) 21%,rgba(1,1,1,0.2) 27%,rgba(10,10,10,0) 42%,rgba(78,78,78,0.3) 76%,rgba(56,56,56,0.6) 87%,rgba(27,27,27,0) 100%), #182d70;

  @include break(md) {
    flex-direction: row;
    justify-content: flex-end;
  }

  .Subtabs-nav-items {
    border: none;
    display: block;

    @include break(md) {
      display: flex;
      flex-basis: 70%;
      flex-grow: 0;
      &:only-child {
        margin: 0;
      }
    }
  }

  .Subtabs-nav-item {
    border-right: none;
    background-color: transparent;;

    .Subtabs-nav-link {
      color: white;
    }

    &.is-active {
      background-color: $color-background-2;

      .Subtabs-nav-link {
        color: $color-foreground-1;
      }
    }

    &:only-of-type {
      border-right: none;
    }
  }

  .Subtabs-nav-dropdown {
    .Form-dropdown {
      color: white;
      border: none;
      &:focus {
        background-color: transparent;
        color: white;
        option {
          background-color: $color-background-2;
          color: $color-foreground-1;
        }
      }
    }
  }
}

.Subtabs-nav-dropdown {
  display: flex;
  margin-bottom: $gutter-v;

  @include break(md) {
    flex-basis: 30%;
    margin-right: $gutter-h*2;
    margin-bottom: 0;
  }

  .Form-dropdown {
    color: $color-foreground-1;
    padding-right: $gutter-h*1.5;
  }
}

.Subtabs-nav-items {
  display: flex;
  border: $border-thin;
  border-radius: $border-radius-1;

  @include break(md) {
    &:only-child {
      margin: 0 auto;
    }
  }
}

.Subtabs-nav-item {
  position: relative;
  flex-grow: 1;
  line-height: 1;
  text-align: center;
  border-right: $border-thin;
  background-color: $color-background-2;
  transition: background-color .3s;

  &.is-active {
    background-color: transparent;

    .Subtabs-nav-link {
      color: $color-foreground-1;
    }
  }

  &:last-of-type {
    border-right: none;
  }
  &:only-of-type {
    border-right: $border-thin;
  }


  &--hiddenMobile {
    display: none;

    @include break(md) {
      display: block;
    }
  }

  &--hidden-md {
    display: none;

    @include break(lg) {
      display: block;
    }
  }
}

.Subtabs-nav-link {
  display: block;
  padding: $gutter-v 0;
  color: $color-foreground-3;
  cursor: pointer;
  transition: color .3s;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xxs);

  &:hover {
    color: $color-foreground-1;
  }
}

.Subtabs-item {
  display: none;

  &.is-active {
    display: block;
  }
}
