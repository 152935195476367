.ContentCard {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-basis: 100%;
  margin-bottom: $gutter-v/1.5;
  padding: $gutter-v/2;
  font-family: $font-secondary;
  background-color: $color-light;
  border-radius: $border-radius-2;
  box-shadow: $box-shadow-low;
  transition: transform .2s, box-shadow .2s;
  cursor: pointer;
  vertical-align: top;

  &--AlwaysColumn {
    flex-direction: column;
  }

  @include break(sm) {
    flex-direction: column;
    width: 48.7%;
    display: inline-block;
    padding: $gutter-v/1.33;
    margin-bottom: $gutter-v*1.5;
    margin-right: 2.5%;

    @include breakMax(md) {
      &:nth-of-type(2n-1) {
        margin-right: 0;
      }

      &:nth-of-type(2n) {
        margin-left: 2.5%;
        margin-right: 0;
      }
    }
  }

  @include break(md) {
    width: 31.6%;

    &:nth-of-type(3n-1) {
      margin-right: 0;
    }

    &:nth-of-type(3n) {
      margin-left: 2.5%;
      margin-right: 0;
    }
  }

  &:hover {
    box-shadow: $box-shadow-soft;
    transform: scale(1.015);
  }

  &--photo {
    flex-basis: 48%;
    margin-bottom: $gutter-v;

    @include break(md) {
      flex-basis: 31.66%;
      margin-bottom: $gutter-v*1.5;
    }
  }
}

.Content-cards--firstLarge {
  .ContentCard {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: row;

    @include break(md) {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: $gutter-h/1.5;
    }
  }
  .ContentCard-frame {
    width: 33%;
    margin-bottom: 0;
  }
}

.ContentCard-frame {
  position: relative;
  width: 33.33%;
  background-color: $color-background-3;
  overflow: hidden;

  @include break(sm) {
    width: 100%;
    margin-bottom: $gutter-v/1.5;
  }

  @include break(md) {
    width: 100%;
  }

  &:only-child {
    width: 100%;
    margin-bottom: 0;
  }
}

.ContentCard-image {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: top center;
  opacity: .9;

  &--compact {
    padding-top: 75%;
  }

  &--portrait {
    background-size: contain;
    background-repeat: no-repeat;
    background-color: $color-dark;
  }
}

.ContentCard-type {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: $gutter-v/4;
  color: $color-light;
  font-size: map-get($font-size-secondary, 3xs);
  background-color: $color-foreground-3;

  @include break(sm) {
    font-size: map-get($font-size-secondary, xxs);
  }
}

.ContentCard-type--featured {
  background-color: $color-action-1;
}

.ContentCard-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: auto;
  padding-left: $gutter-h/2;
  background-color: $color-light;
}

.ContentCard-date {
  margin-top: 0;
  margin-bottom: $gutter-v/4;
  color: $color-foreground-4;
  font-size: map-get($font-size-secondary, 3xs);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;
}

.ContentCard-title {
  margin-top: 0;
  margin-bottom: $gutter-v/3;
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, xs);
  font-weight: 600;
  line-height: 1.2;
  text-transform: none;
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @include break(sm) {
    font-size: map-get($font-size-secondary, sm);
    margin-bottom: $gutter-v/2.5;
  }
}

.ContentCard-excerpt {
  margin-top: 0;
  margin-bottom: 0;
  color: $color-greyscale-6;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, xxs);
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
  max-width: 100%;

  @include break(sm) {
    font-size: map-get($font-size-primary, sm);
  }
}

.MultilineEllipsis {
  overflow: hidden;
  position: relative;
  text-align: justify;
  height: 4.2em;
  -webkit-line-clamp: 3;
  display: -moz-box;
  display: -webkit-box;
  display: box;
  -moz-box-orient: vertical;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  box-orient: vertical;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: -1px;
    background-color: $color-light;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }

  @supports (-webkit-line-clamp: 3) {
    text-align: start;
    height: auto;
    overflow: hidden;

    &:before {
      content: '';
    }
  }
}