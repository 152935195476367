.DownloadsDonors-container {
  align-items: flex-start !important;

  @include breakMax(md) {
    flex-direction: column;
  }

  .Featured-header-title {
    margin-bottom: $gutter-v*1.5;
  }

  .DownloadsDonors {
    width: 100%;

    @include break(md) {
      width: 50%;
    }
  }

  .download {
    color: $color-action-2;
    text-decoration: underline;
  }

  .link {
    margin-bottom: $gutter-v/2;

    @include breakMax(md) {
      text-align: center;
    }
  }
}