.PressKit {

  &-header{
    border-bottom: 1px solid $color-foreground-5;
  }

  &-subtitle {
    text-align: center;
  }

  &-content {
    padding: $gutter-v 0;
  }

  li {
    color: $color-foreground-1;
  }
}
