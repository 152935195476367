.Article-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: $container-width-1;

  @include break(md) {
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: $container-width-3;
  }
}

.Article-content {
  width: 100%;
  margin-bottom: $gutter-v * 1.5;

  @include break(md) {
    flex-basis: 72.5%;
    margin-bottom: 0;
    padding-right: $gutter-h * 3;
  }

  @include break(lg) {
    padding-right: $gutter-h * 4;
  }

  &:only-child {
    margin: 0 auto;

    @include break(md) {
      padding: 0 $gutter-h*1.5
    }
    @include break(lg) {
      padding: 0 $gutter-h*2
    }
  }
}

.Article-content-label {
  margin: ($gutter-v / 2) 0;
  color: $color-foreground-3;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, xxs);
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;

  @include break(md) {
    font-size: map-get($font-size-secondary, sm);
  }
}

.Article-content-headline {
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, md);
  line-height: 1.4;

  @include break(sm) {
    font-size: map-get($font-size-secondary, lg);
  }

  @include break(lg) {
    font-size: map-get($font-size-secondary, xl);
  }
}

.Article-content-excerpt {
  margin-top: 0;
  margin-bottom: $gutter-v;
  color: $color-foreground-2;
  font-size: map-get($font-size-primary, sm);
  line-height: 1.5;
  font-family: 'futurabold';

  @include break(md) {
    margin-bottom: $gutter-v;
    font-size: map-get($font-size-primary, md);
  }
}

.Article-content-cover {
  position: relative;
  margin-bottom: $gutter-v ;
  padding-top: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include break(md) {
    margin-bottom: $gutter-v * 1.5;
  }
}

.Article-content-cover-badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: ($gutter-v / 3) ($gutter-h / 2.5);
  background-color: $color-foreground-3;
  color: $color-light;
  font-family: $font-secondary;
  font-size: map-get($font-size-primary, xs);
  letter-spacing: $letter-spacing-1;

  @include break(sm) {
    padding: ($gutter-v / 2.5) ($gutter-h / 2);
  }

  @include break(lg) {
    padding: ($gutter-v / 2) ($gutter-h / 1.5);
    font-size: map-get($font-size-primary, sm);
  }
}

.Article-content-body {
  color: $color-foreground-2;
  font-size: map-get($font-size-primary, xs);
  line-height: 1.5;

  img {
    display: block;
    width: 100% !important;
    height: auto !important;
  }

  p,
  ul {
    padding-bottom: 2rem;
  }

  a {

  }

  @include break(md) {
    font-size: map-get($font-size-primary, sm);
  }



  @include breakMax(sm) {
    iframe {
      width: 32rem;
      height: 19rem;
    }
  }

  // Couldn't use break because it was looking ugly on iphone 5
  @media (max-width: 320px) {
    iframe {
      width: 28rem;
      height: 17rem;
    }
  }


  iframe {
    display: block;
    margin: 0 auto;
    border: 0;

    @include break(md) {
      max-width: 100%;
      padding: $gutter-h;
    }
  }
}

.Article-links {
  width: 100%;
  margin-bottom: 10px;

  @include break(md) {
    display: block;
    flex-basis: 27.5%;
  }
}

.Article-links-title {
  margin: 0 0 2.7rem;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, sm);
}

.Article-links-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Article-links-list-item {
  margin: 0;
  padding: $gutter-v / 2;
  list-style: none;
  margin-bottom: $gutter-v;
  background: white;
  box-shadow: 0 2.25rem 1.75rem -1rem rgba(44, 39, 109, 0.1);
  border-radius: 0.5rem;
  transition: transform 0.4s, box-shadow 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.45);
    transform: translateY(-10px);
  }
}

.Article-links-list-item-date {
  margin-top: 0;
  margin-bottom: $gutter-v / 3;
  color: $color-foreground-3;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, 3xs);
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;

  @include break(lg) {
    font-size: map-get($font-size-secondary, xxs);
  }
}

.Article-links-list-item-image {
  display: block;
  width: 100%;
  height: 150px;
  background-position: 50% 50%;
  background-size: cover;
  margin-bottom: $gutter-v / 4;
}

.Article-links-list-item-headline {
  margin: 0;
  color: $color-foreground-1;
  font-size: 18px;
  display: block;
}
