.MediaAndSponsorsAbout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include break(md) {
    flex-direction: row;
    margin: $gutter-v/3 0;
  }

  &-info {
    width: 100%;
    max-width: 54rem;
    margin: 0 auto $gutter-v;
    color: $color-foreground-2;
    font-size: map-get($font-size-primary, md);
    line-height: 1.4;
    text-align: left;

    @include break(md) {
      flex-basis: 66.66%;
      max-width: 100%;
      margin-bottom: $gutter-v/2;
      padding-right: $gutter-h*2;
    }
  }

  &-logos {
    display: flex;

    @include break(md) {
      flex-direction: column;
      flex-basis: 33.33%;
      align-items: center;
    }
  }

  &-logo {
    display: block;
    transition: opacity .3s;

    img {
      max-height: 3.6rem;
    }

    @include break(md) {
      margin: $gutter-v 0;

      img {
        max-height: 4.2rem;
      }
    }
  }
}
