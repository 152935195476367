.CountryFooter {

  &-infoWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: $gutter-v;

    @include break(md) {
      flex-wrap: nowrap;
    }
  }

  &-info {
    flex-basis: 100%;
    padding: 0 $gutter-h;
    margin-bottom: $gutter-v/2;
    font-size: map-get($font-size-primary, md);
    font-weight: 500;
    color: $color-foreground-1;

    p {
      margin-top: 0;
      margin-bottom: $gutter-v/2;
    }

    @include break(md) {
      flex-basis: auto;
      padding: 0 $gutter-h*1.25;
    }
  }

  &-link {
    color: $color-foreground-2;
    transition: color .2s;

    &:hover {
      color: $color-foreground-3;
    }
  }
}
