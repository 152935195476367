.SidebarPagination {
  display: inline-block;
  cursor: pointer;
  font-family: $font-secondary;

  &--prev {
    text-align: right;

    .SidebarPagination-description {
      span {
        padding-left: map-get($font-size-primary, sm);
      }

      img {
        transform: rotate(180deg);
      }
    }
  }

  &--next {
    float: right;

    .SidebarPagination-description {
      span {
        padding-right: map-get($font-size-primary, sm);
      }
    }
  }

  &-label {
    text-transform: uppercase;
    color: $color-foreground-3;
  }

  a:hover {
    color: $color-foreground-1;
  }

  img {
    height: map-get($font-size-primary, 3xs);
  }
}