.NavBar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  background-color: $color-background-4;
  z-index: 2;

  @include break(md) {
    max-width: $container-width-3;
    background-color: $color-light;
  }
}

.NavBar-primary {
  display: none;

  @include break(md) {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    line-height: 1;
    list-style: none;
  }

  li {
    display: block;
    margin: 0 $gutter-h/2;

    @include break(lg) {
      margin: 0 $gutter-h;
    }

  }

  .NavBar-primary-link {
    position: relative;
    display: block;
    padding-bottom: 1.8rem;
    color: $color-foreground-1;
    font-family: $font-secondary;
    font-size: map-get($font-size-secondary, xs);
    white-space: nowrap;

    @include break(lg) {
      font-size: map-get($font-size-secondary, sm);
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: auto;
      padding-top: 2px;
      background-color: $color-highlight;
      transform: scaleX(0);
      transition: transform .3s;
    }

    &:hover {
      &::after {
        transform: scaleX(1.2);
      }
    }
  }

  .NavBar-primary-icon {
    cursor: pointer;

    svg {
      width: 18px;
    }
  }
}

.NavBar-secondary {
  display: none;

  @include break(md) {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    justify-content: center;
    list-style: none;
  }

  li {
    padding: $gutter-v/3 $gutter-h/2.5;
    background-color: $color-background-1;

    @include break(lg) {
      padding: $gutter-v/3 $gutter-h/1.5;
    }

    &:first-of-type {
      padding-left: $gutter-h;
      border-bottom-left-radius: $border-radius-2;
    }

    &:last-of-type {
      padding-right: $gutter-h;
      border-bottom-right-radius: $border-radius-2;
    }
  }

  .NavBar-secondary-link {
    font-family: $font-primary;
    font-weight: 500;
    font-size: map-get($font-size-primary, xxs);
    color: $color-foreground-3;
    transition: color .2s;

    &:hover {
      color: $color-foreground-1;
    }

    &--dropdown,
    &--login {
      background-color: $color-background-1;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: 1rem auto;
      border: none;
      appearance: none;
      outline: none;
      cursor: pointer;
    }

    &--dropdown {
      padding-right: 1.2rem;
      background-image: url(cdn_image('/img/icon-drop.svg'));
    }

    &--login {
      padding-right: 1.5rem;
      background-image: url(cdn_image('/img/icon-login.svg'));
    }
  }
}

.NavBar-social {
  display: none;

  @include break(lg) {
    display: flex;
    width: 18%;
    max-width: 15rem;
    margin: 0;
    padding: 0;
    line-height: 0;
    list-style: none;
    justify-content: flex-end;
    align-items: center;
  }

  .NavBar-social-icon {
    display: block;
    width: 20%;
    height: 56px;
    padding-top: $gutter-v;
    padding-bottom: $gutter-v;
    padding-left: 9%;
  }
}

.NavBar-toggle {
  display: flex;
  justify-content: center;
  padding: ($gutter-v / 2) 0;
  margin-bottom: 0.75rem;
  margin-left: $gutter-h * 1.75;
  cursor: pointer;
  white-space: nowrap;

  @include break(md) {
    margin-left: $gutter-h;
  }

  @include break(lg) {
    display: none;
  }

  .NavBar-toggle-label {
    margin-right: $gutter-h / 2;
    color: $color-light;
    font-family: $font-primary;
    font-size: map-get($font-size-primary, xs);
    font-weight: 500;
    letter-spacing: $letter-spacing-1 / 2;
    line-height: 1;
    transition: opacity .2s;

    @include break(md) {
      display: none;
    }

    &--follow {
      display: none;
      margin-right: 0;
      color: $color-foreground-3;

      @include break(md) {
        display: block;
      }
    }
  }

  .NavBar-toggle-icon {
    position: relative;
    min-width: $gutter-v;
    margin: .6rem 0;
    border-top: .2rem solid $color-foreground-3;
    transition: transform .2s, border-color .2s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      border-top: .2rem solid $color-foreground-3;
      transition: transform .2s, border-color .2s;
    }

    &::before {
      top: -.7rem;
    }

    &::after {
      bottom: -.5rem;
    }

    @include break(md) {
      border-color: transparent;
      transform: scale(.5);

      &::before {
        transform: rotate(90deg) translateX(.5rem);
      }

      &::after {
        transform: translateY(-.5rem);
      }
    }
  }

  &.is-on {
    .NavBar-toggle-label {
      opacity: 0;
    }

    .NavBar-toggle-icon {
      border-color: transparent;
      transform: translateX(.5rem);
    }

    .NavBar-toggle-icon::before {
      border-color: $color-light;
      transform: rotate(45deg) translateY(.7rem);
    }

    .NavBar-toggle-icon::after {
      border-color: $color-light;
      transform: rotate(-45deg) translateY(-.7rem);
    }

    @include break(md) {
      .NavBar-toggle-icon {
        transform: scale(1);
      }

      .NavBar-toggle-icon::before {
        border-color: $color-foreground-1;
      }

      .NavBar-toggle-icon::after {
        border-color: $color-foreground-1;
      }
    }
  }
}
