/**
 * Modal
 *
 * Example HTML:
 *
 * <div class="Modal">
 *   ...
 * </div>
 */

$modalWidth: 44rem;

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: transparentize($color-dark, 0.5);
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Modal--bio {
  .Modal-container {
    position: relative;
  }

  .Modal-close {
    position: absolute;
    top: 7px;
    right: 7px;
    color: #182d70;
    font-size: 3rem;
    line-height: 1;
    background: none;
    outline: none !important;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: block;
    padding: 0;
    margin: 0;
  }
}

.Modal--headToHead {
  color: $color-foreground-2;
  font-size: map-get($font-size-primary, xs);

  .Modal-contentTop {
    background: #182d70;
  }

  .Modal-subHeader {
    color: white;
    font-size: 1.8rem;
  }

  .Modal-header {
    color: white;
    margin-bottom: 1rem;
    padding: 0 15px;
    &::before {
      display: none;
    }

    h2 {
      color: white;
    }
  }

  .Modal-content,
  .Modal-container {
    position: relative;
  }

  .Modal-close {
    position: absolute;
    top: 7px;
    right: 7px;
    color: white;
    font-size: 3rem;
    line-height: 1;
    background: none;
    outline: none !important;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: block;
    padding: 0;
    margin: 0;
  }
}

.Modal--bigger {
  color: $color-foreground-1;
  font-size: map-get($font-size-primary, xs);
}

.Modal.is-active {
  visibility: visible;
  opacity: 1;
}

/**
 * Modal-content
 *
 * Example HTML:
 *
 * <div class="Modal-container">
 *   <div class="Modal-content">
 *     ...
 *   </div>
 * </div>
 */

.Modal-container {
  max-width: $modalWidth;
  margin: auto;
  background-color: $color-light;
  border-radius: $border-radius-1;

  &--large {
    max-width: $modalWidth * 1.5;
  }

  &--xlarge {
    max-width: $modalWidth * 2;
  }

  &--mt-sm {
    margin-top: $gutter-v * 2;
  }
}

.Modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $gutter-v $gutter-h;
  text-align: center;

  input.Form-text--email {
    background-color: $color-background-2;
    text-align: left;
    font-size: map_get($font-size-primary, xs);
  }
}

.Modal-media {
  position: relative;
  width: 100%;
  text-align: center;
}

.Modal-video {
  position: relative;
  width: 100%;
  padding-top: 60%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.Modal-header {
  display: flex;
  align-items: center;

  &::before {
    display: inline-block;
    content: '';
    width: $gutter-h*2;
    height: $gutter-v*2;
    background-image: url(cdn_image('/img/icon-bell-blue.svg'));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.Modal--tranparent {
  background-color: transparent;
}

.Modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: transparentize($color-dark, 0.5);
}

.Modal-header--noIcon {
  display: flex;
  align-items: center;

  &::before {
    display: none;
  }
}


.Modal-subHeader {
  padding-right: $gutter-h;
  padding-left: $gutter-h;
  text-align: center;
  max-width: 100%;

  @include break(md) {
    padding-right: $gutter-h * 1.5;
    padding-left: $gutter-h * 1.5;
    max-width: 40rem;
  }

  &.Form--footer {
    font-family: $font-primary;
    text-transform: none;
  }
}

.Modal-close,
.Modal-close .Button--link {
  display: flex;
  justify-content: center;
  font-size: map-get($font-size-primary, md);
  color: $color-foreground-2;
  padding: $gutter-v $gutter-h;
  cursor: pointer;
}


.Modal-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  height: $gutter-v*3;
  margin: auto;
  border-radius: 0;

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }
}

.Modal-img {
  max-height: 50vh;
}
