.Preliminary {
  margin-top: $gutter-v;

  .Tableau-round::after {
    display: none;
  }

  .Tableau-fencers {
    margin: 0;
  }
}
