.Document {
  padding-bottom: $gutter-v;
  overflow: hidden;
  background-color: $color-background-1;

  @include break(md) {
    padding: $gutter-v*3 0;
  }

  &-header {
    position: relative;

    @include break(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $gutter-v*1.5;
    }

    &--white {
      h1, a, a:hover, a.is-active {
        color: $color-light;
      }

      select.Form-dropdown {
        background-color: $color-light;
      }
    }
  }

  &--extra {
    h3 {
      margin-top: 3rem !important;
    }

    .MemberAdmin-heading {
      padding: 0;
    }
    @include break(md) {
      margin-top: -$gutter-v*3;
    }
  }

  &-title {
    letter-spacing: $letter-tightening-2;
  }

  &-dropdowns {
    max-width: 36rem;
    margin-bottom: $gutter-v;

    @include break(lg) {
      display: none;
    }

    & > select {
      margin-bottom: $gutter-v/2;
    }
  }

  &-tabs {
    display: none;

    &-link {
      margin-left: $gutter-h;
      font-family: $font-secondary;
      font-size: map-get($font-size-secondary, sm);
      color: $color-foreground-3;
      transition: color .2s;

      @include break(lg) {
        margin-left: $gutter-h*2;
      }

      &.is-active,
      &:hover {
        color: $color-foreground-1;
      }

      &.is-active {
        padding-bottom: $gutter-v/3;
        border-bottom: $border-highlight;
      }
    }

    @include break(lg) {
      display: flex;
      margin-top: $gutter-h*.75;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include break(md) {
      flex-direction: row;
    }

    section {
      padding-top: $gutter-v * 3;
    }

    strong {
      font-family: $font-secondary;
    }

    &-inner {
      h3 {
        margin-top: $gutter-v * 2;
        margin-bottom: $gutter-v;
      }
      .Grid + .Grid {
        margin-top: $gutter-v * 2;
      }
    }

    &-sidebar {
      width: 100%;
      @include break(md) {
        display: flex;
        flex-basis: 31.33%;
        flex-direction: column;
        margin-left: $gutter-h*3;
      }

      @include break(lg) {
        flex-basis: 27%;
      }

      &--left {
        margin-left: 0;
        margin-right: $gutter-h * 3;
      }
      &--right {
        @media (max-width: 767.98px) {
          padding: 60px 0 35px 0;
          background: url(../img/executive-committee-header-bg.jpg) no-repeat center;
          background-size: cover;
        }
      }
      &--small {
        .Document-content-sidebarLink {
          margin-bottom: $gutter-v*.75;
          padding-bottom: $gutter-v*.75;

          &--expanded {
            margin-bottom: 0;
            padding-bottom: $gutter-v/2;
          }

          &-submenu {
            margin-bottom: $gutter-v*.75;
            padding-bottom: $gutter-v*.75;
          }
        }
      }
      &Link {
        display: block;
        margin-bottom: $gutter-v * 1.33;
        padding-bottom: $gutter-v * 1.33;
        font-family: $font-secondary;
        font-size: map-get($font-size-secondary, xs);
        border-bottom: $border-thin;
        color: $color-foreground-3;
        transition: color .2s;
        span.linksmall {
          font-size: 12px;
        }
        &.is-active {
          color: $color-foreground-1;
        }

        &:hover {
          color: $color-foreground-1;
        }

        &--expanded {
          margin-bottom: 0;
          padding-bottom: $gutter-v/2;
          border-bottom: none;
        }

        &-submenu {
          padding-bottom: $gutter-v * 1.33;
          margin-bottom: $gutter-v * 1.33;
          border-bottom: $border-thin;
          font-family: $font-secondary;
          font-size: map-get($font-size-primary, xs);

          a {
            position: relative;
            display: block;
            padding: $gutter-v/4 0 $gutter-v/4 $gutter-h*1.5;
            color: $color-foreground-3;
            transition: color .2s;

            &:hover {
              color: $color-foreground-1;
            }

            &::before {
              position: absolute;
              top: 50%;
              left: $gutter-h/2;
              height: .8rem;
              width: .8rem;
              background-color: $color-foreground-5;
              pointer-events: none;
              border-radius: 50%;
              content: '';
              transform: translateY(-50%);
              transition: background-color .2s, box-shadow .2s, transform .2s;
            }

            &.is-active {
              color: $color-foreground-1;

              &::before {
                background-color: $color-highlight;
                border: 1px solid rgba($color-light, 0.66);
                box-shadow: 0 0 0.4rem 0 rgba($color-highlight, 0.75);
                transform: translateY(-50%) scale(1.25);
              }
            }
          }
        }
      }
    }

    &-text {
      width: 100%;

      h2,
      h3,
      h4 {
        margin-top: $gutter-v*1.5;
        text-transform: capitalize;

        &:first-of-type {
          margin-top: 0;
        }
      }
      h3 {
          margin-bottom: 15px;
      }
      ol {
        color: $color-foreground-2;
        font-size: map-get($font-size-primary, xs);
        @include break(md) {
          font-size: map-get($font-size-primary, sm);
        }
      }

      p, ul {
        margin-top: 0;
        margin-bottom: $gutter-v/1.33;
        padding: 0;
        color: $color-foreground-2;
        font-size: map-get($font-size-primary, xs);
        line-height: 1.4;
        list-style: none;

        @include break(md) {
          font-size: map-get($font-size-primary, sm);
        }
      }

      &--light {
        color: $color-foreground-3;
      }

      &--secondary {
        padding: $gutter-v/2 $gutter-h * 1.5;
      }
    }

    &-subheader {
      color: $color-foreground-4;
      text-align: left;
      text-transform: uppercase;
      font-family: $font-secondary;
    }

    &-body {
      ul {
        ul {
          margin-left: $gutter-h*1.5;

          a {
            display: block;
            position: relative;

            &::before, &::after {
              content: '';
              position: absolute;
              display: block;
              bottom: 0;
              left: -1.75rem;
              margin: auto;
              width: 100%;
              height: 100%;
            }

            &::before {
              top: 0;
              max-width: 1.75rem;
              max-height: 1px;
              border-bottom: $border-thin--dark;
            }

            &::after {
              top: -2rem;
              max-width: 1px;
              max-height: 2rem;
              border-left: $border-thin--dark;
            }
          }
        }
      }

      &-image {
        display: block;
        margin: auto;
        width: 40rem;
      }
    }

    &--withTopImage {
      padding-top: 6rem;

      @include break(md) {
        padding-top: 10rem;
      }

      @include break(lg) {
        padding-top: 32rem;
      }
    }
  }

  &-panel {
    margin-bottom: $gutter-v;
    padding: ($gutter-v) ($gutter-h);
    background-color: $color-light;
    box-shadow: $box-shadow-low;
    border-radius: $border-radius-1;

    @include break(sm) {
      margin-bottom: $gutter-v * 1.25;
    }

    @include break(md) {
      flex-basis: 100%;
    }
  }

  &-externalDocument {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $gutter-v/1.5;
    padding: $gutter-v*.75 $gutter-h*.75;
    color: $color-foreground-2;
    font-weight: 500;
    background-color: $color-background-3;
    border-radius: $border-radius-1;
    transition: box-shadow .2s, background-color .2s;

    &:hover {
      background-color: $color-light;
      box-shadow: $box-shadow-soft;


      span:first-child {
        color: $color-foreground-2;
      }
    }

    span:last-child {
      padding-left: $gutter-v/2;
    }

    span:first-child {
      font-weight: 500;
      padding-left: 0;
      color: $color-foreground-2;
    }
  }

  &-table {
    margin: $gutter-v 0;

    @include break(md) {
      margin-top: $gutter-v * 2;
      margin-bottom: $gutter-v * 1.5;
    }

    table {
      padding: $gutter-v $gutter-h;
      text-align: left;
      color: $color-foreground-1;
      font-size: map-get($font-size-primary, xs);
      background: $color-light;
      border-radius: $border-radius-2;
      box-shadow: $box-shadow-low;

      @include break(md) {
        font-size: map-get($font-size-primary, sm);
      }

      p {
        text-align: left;
        color: $color-foreground-2;
        margin: 0;
      }

      td, th {
        padding: $gutter-v/4 $gutter-h/4;
        vertical-align: top;
        display: block;

        &:first-child {
          width: 17rem;
          font-family: $font-secondary;
          font-weight: 500;
          font-size: map-get($font-size-primary, xs);
          text-transform: uppercase;
          color: $color-foreground-3;

          @include break(md) {
            width: 17rem;
          }
        }

        &:last-child {
          margin-bottom: $gutter-v/2;

          @include break(md) {
            margin-bottom: 0;
          }
        }

        @include break(md) {
          padding: $gutter-v/2 $gutter-h/2;
          display: table-cell;
        }
      }
    }
  }

  &--council {

    &-grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: $gutter-v;
    }

    &-item {
      margin-right: $gutter-h/2;
      margin-bottom: $gutter-v/2;

      a {
        text-transform: capitalize;
      }
    }
  }

  &--executive-committee,
  &--members-of-honour,
  &--manufacturers,
  &--office,
  &--presidents,
  &--confederations,
  &--federations-map,
  &--council {
    .MediaAndSponsorsAdmin-card,
    .Manufacturers-card,
    .MemberAdmin-card {
      @include break(md) {
        flex-basis: 48%;
        justify-content: space-around;
        margin-bottom: $gutter-v*1.5;
      }
    }
  }

  hr {
    border: none;
    border-bottom: 2px solid $color-foreground-6;
    margin: $gutter-v*2 0;
  }

}

.GeneralSearch {
  padding-bottom: $gutter-v;

  @include break(md) {
    padding: $gutter-v*3 0;
  }

}
