.Hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(cdn_image('/img/hero-bg.jpg'));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $color-background-6;
  height: 25rem;
  width: 100%;

  @include break(md) {
    height: 40rem;

    .Hero-container {
      padding-top: 3.7rem;
    }
  }

  @include break(lg) {
    height: 50rem;
  }

  &--discover {
    height: 20rem;

    @include break(md) {
      height: 28rem;
    }

    @include break(lg) {
      height: 34rem;
    }
  }

  &--carousel {
    position: absolute;
    top: 0;
    height: 100%;
    background-image: none;
    background-color: transparent;
  }

  &-wrapper {
    position: relative;
    cursor: pointer;

    @include break(md) {
      margin-top: 4.7rem;
    }

    @include break(lg) {
      margin-top: 5.6rem;
    }
  }
}

.Hero-container {
  max-width: 56rem;
  text-align: center;
  color: $color-light;
  position: relative;

  @include break(md) {
    max-width: 72rem;
  }

  @include break(lg) {
    max-width: 86rem;
  }
}

h2.Hero-label {
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  color: $color-light;
  font-size: map-get($font-size-secondary, xxs);
  text-transform: uppercase;
  letter-spacing: $letter-spacing-2;
  white-space: normal;

  @include break(md) {
    font-size: map-get($font-size-secondary, sm);
  }

  @include break(lg) {
    font-size: map-get($font-size-secondary, md);
  }
}

h1.Hero-title {
  margin-top: 0;
  margin-bottom: $gutter-v / 2;
  color: $color-light;
  font-size: map-get($font-size-secondary, 3xl)/2;
  line-height: 1.3;
  white-space: normal;

  @include break(md) {
    font-size: map-get($font-size-secondary, xxl);
    letter-spacing: $letter-tightening-1;
  }

  @include break(lg) {
    font-size: map-get($font-size-secondary, 3xl);
  }

  &--marginBottom {
    margin-bottom: $gutter-v;
  }
}

.Hero-excerpt {
  margin-top: 0;
  margin-bottom: $gutter-v * 1.5;
  font-size: map-get($font-size-primary, sm);
  font-weight: 500;
  letter-spacing: $letter-spacing-1;
  line-height: 1.4;
  white-space: normal;

  @include break(md) {
    font-size: map-get($font-size-primary, md);
  }

  @include break(lg) {
    font-size: map-get($font-size-primary, lg);
  }
}

.Hero-backgroundImage, .Hero-backgroundColor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.Hero-backgroundImage--grayscale {
  filter: #{'grayscale()'};
}

.Hero-backgroundColor {
  opacity: 0.8;
}

.HomeCarouselContainer {

  .frame {
    width: 100%;
  }

  .slides {
    width: 100%;
  }

  li {
    width: 100%;
    background-color: $color-light;

    @include break(md) {
      .Hero-container {
        padding-top: 3.7rem;
      }
    }
  }

  .HomeCarousel-arrows {
    .HomeCarousel-arrow {
      cursor: pointer;
      padding: $gutter-h/2;

      &.prev {
        position: absolute;
        top: 50%;
        left: 0;
      }

      &.next {
        position: absolute;
        top: 50%;
        right: 0;
      }
    }

    @include breakMax(md) {
      display: none;
    }
  }

  .dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 0;

    li {
      width: $gutter-h * 1.5;
      height: $gutter-v / 10;
      background-color: transparentize($color-light, 0.5);
      margin: 0 $gutter-h / 5;
      &.active {
        background-color: $color-light;
      }
    }
  }

  .HomeCarousel-iframe {
    fill: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: none;

    @include break(md) {
      display: block;

      iframe {
        width: 230px;
        height: 76px;
      }
    }

    @include break(lg) {
      height: 130px;

      iframe {
        width: 330px;
        height: 107px;
      }
    }

    svg {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    iframe {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.showSmall {
  background-color: $color-dark;
  text-align: center;

  @include break(md) {
    display: none;
  }
}

.Hero-image {
  width: 100%;
}
