.CountryHero {
  position: relative;
  padding: $gutter-v*2 0;

  @include break(md) {
    padding: 4rem 0;
  }

  .Container {
    position: relative;
    z-index: 2;
  }
}

.CountryHero-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: url(../img/executive-committee-header-bg.jpg) no-repeat center;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.CountryHero-country {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: $gutter-v;
  width: 100%;

  @include break(md) {
    margin-bottom: $gutter-v;
  }
}

.CountryHero-flag {
  max-width: 2rem;

  @include break(md) {
    max-width: 3.6rem;
  }

  @include break(lg) {
    max-width: 5rem;
  }
}

.CountryHero-name {
  display: flex;
  justify-content: center;
  margin-left: $gutter-h/2;
  color: $color-light;
  line-height: 1;
  font-size: map-get($font-size-secondary, lg);
  text-transform: capitalize;

  @include break(md) {
    margin-left: $gutter-h/1.5;
    font-size: map-get($font-size-secondary, xxl);
  }

  @include break(lg) {
    margin-left: $gutter-h;
    font-size: map-get($font-size-secondary, 3xl);
  }
}

.CountryHero-medals {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-secondary;

  @include break(md) {
    flex-direction: row;
  }

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: $gutter-v/3;

    @include break(md) {
      padding: 0 $gutter-h/2;
    }
  }

  &-qty {
    color: $color-light;
    font-size: map-get($font-size-secondary, lg);
  }

  &-label {
    color: $color-light;
    margin-left: $gutter-h/2;
    font-size: map-get($font-size-secondary, xs);

    @include break(md) {
      font-size: map-get($font-size-secondary, sm);
    }
  }
}

.CountryUpcoming {
  padding-top: 2rem;

  &-link,
  &-text {
    &:hover {
      color: $color-light;
    }
    color: $color-light;
  }

  .ProfilePics {
    a.ProfilePics-image.CountryUpcoming-image {
      border: 1px solid $color-light;
      @include break(md) {
        width: 12rem;
        height: 12rem;
      }
    }
  }
}
