.AthleteGrid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include break(md) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: $gutter-v * 2;

    .AthleteCard {
      &:nth-of-type(1) {
        margin-right: 2.5%;
      }

      &:nth-of-type(3) {
        margin-left: 2.5%;
      }

      &:only-of-type {
        margin: 0;
      }
    }
  }
}

.AthleteGrid--noMargin {
  margin-bottom: 0;
}

.AthleteGrid--topFencers {
  .AthleteCard {
    display: none;

    &:first-child {
      display: flex;
    }
  }

  @include break(md) {
    .AthleteCard {
      display: flex;
    }
  }
}
