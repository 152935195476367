@import "cdn.scss";

// Colors

$color-highlight: #2e8ee0;
$color-highlight-2: #0B9DDE;
$color-highlight-3: #17469E;

$color-shadow: rgba(44,39,109,0.1);

$color-foreground-1: #182d70;
$color-foreground-2: #182d70;
$color-foreground-3: #182d70;
$color-foreground-4: #182d70;
$color-foreground-5: #e2e4f6;

$color-foreground-6: rgba(213, 215, 233, 0.39);

$color-background-1: #fff;
$color-background-2: #e9e9f1;
$color-background-3: #fff;
$color-background-4: #2c276d;
$color-background-5: #53529f;
$color-background-6: #413c85;
$color-foreground-7: rgba(59, 58, 67, 0.33);

$color-light: #fff;
$color-dark: #000;
$color-error: #fe4f20;
$color-success: #1D993B;
$color-warning: #E65963;
$color-action-1: #51DBF9;
$color-action-2: #4CB4DC;
$color-gold: #FFF8D4;
$color-silver: #F0F0F0;
$color-bronze: #F8E7DB;
$color-victory: #EDFBE9;

$color-greyscale-1: #111;
$color-greyscale-2: #333;
$color-greyscale-3: #555;
$color-greyscale-4: #777;
$color-greyscale-5: #999;
$color-greyscale-6: #6a6f75;
$color-greyscale-7: #ccc;
$color-greyscale-8: #eee;

// Font Stacks

$font-primary-safe: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-secondary-safe: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-primary: "franklin-gothic-urw", $font-primary-safe; // for body
$font-secondary: "futura-pt", $font-secondary-safe; // for titles

// Font Sizes

$font-size-primary: (
  3xs: 1.0rem,
  xxs: 1.2rem,
  xs: 1.4rem,
  sm: 1.6rem,
  md: 1.8rem,
  lg: 2.1rem,
  xl: 2.4rem,
  xx: 3.6rem
);

$font-size-secondary: (
  3xs: 1.2rem,
  xxs: 1.4rem,
  xs: 1.6rem,
  sm: 1.8rem,
  md: 2.1rem,
  lg: 2.4rem,
  xl: 3.6rem,
  xxl: 4.5rem,
  3xl: 6.0rem,
  4xl: 7.2rem
);

// $font-size-secondary: (
//   3xs: 1.6rem,
//   xxs: 1.8rem,
//   xs: 2.1rem,
//   sm: 2.4rem,
//   md: 3.6rem,
//   lg: 4.5rem,
//   xl: 6.0rem,
//   xxl: 7.2rem,
//   3xl: 8.6rem,
//   4xl: 10.0rem
// );

// Border Radius

$border-radius-1: .4rem;
$border-radius-2: .6rem;
$border-radius-circle: 50%;
$border-radius-circle: 0%;

// Borders

$border-thin: 1px solid $color-foreground-5;
$border-thin-2: 1px solid $color-foreground-6;
$border-thin--dark: 1px solid $color-foreground-2;

$border-highlight: 3px solid $color-highlight;
$border-highlight--thick: 5px solid $color-highlight;

// Spacing

$gutter-v: 2rem;
$gutter-h: 2rem;

// Letter Spacing

$letter-spacing-1: .05rem;
$letter-spacing-2: .25rem;
$letter-spacing-3: .6rem;
$letter-spacing-4: .8rem;
$letter-spacing-5: 1rem;

// Letter Tightening

$letter-tightening-1: -.05rem;
$letter-tightening-2: -.1rem;

// Container Widths

$container-width-1: 58rem;
$container-width-2: 98rem;
$container-width-3: 110rem;

// Shadows

$box-shadow-even: 0 0.5rem 3rem 0 $color-shadow;
$box-shadow-low: 0 2.25rem 1.75rem -1rem $color-shadow;
$box-shadow-soft: 0 1.5rem 3.5rem -0.5rem $color-shadow;

// Nav Height:

$nav-height-mobile: $gutter-v * 1.5;
$nav-height-md: $gutter-v * 3.6;

// Flags

$flag-icon-css-path: '/img/flags';
$flag-icon-rect-path: '/4x3';
$flag-icon-square-path: '/1x1';
