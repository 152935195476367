.Partners {
  padding-top: 0;

  @include break(md) {
    padding-top: $gutter-v*2;
  }

  @include break(lg) {
    padding-top: $gutter-v*3;
  }

  .ContentCard-content {
    @include break(lg) {
      min-height: 12rem;
    }
  }
}

.Partners-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $gutter-v*1.5;
  padding: 0;

  @include break(md) {
    flex-direction: row-reverse;
    margin-bottom: $gutter-v*2;
    padding-left: $gutter-h*2;
    padding-right: $gutter-h*2;
  }
}

.Partners-header-title {
  margin: 0 auto;
  font-size: map-get($font-size-secondary, lg);
  text-align: center;

  @include break(md) {
    display: initial;
    flex-basis: 50%;
    font-size: map-get($font-size-secondary, xl);
    text-align: left;

    &::after {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.Partners-header-partners {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: $gutter-v*2;
  padding: $gutter-v 0 $gutter-v/1.5;
  background-color: $color-light;
  box-shadow: $box-shadow-even;

  @include break(md) {
    justify-content: flex-end;
    flex-basis: 50%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.Partners-header-logo {
  display: block;

  img {
    max-height: 3.6rem;
  }

  @include break(md) {
    img {
      max-height: 4.2rem;
    }
  }
}
