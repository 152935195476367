$minHeight: 25rem;

.PhotoCard {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: $gutter-v;

  &:first-child {
    display: flex;

    @include break(md) {
      margin-left: 0;
    }
  }

  @include break(md) {
    display: flex;
    flex-basis: 31%;

  }
}

.PhotoCard-image {
  width: 100%;
  min-height: $minHeight;
  background-size: cover;
  background-position: center top;

  @include break(md) {
    min-height: $minHeight / 2;
  }

  @include break(xl) {
    min-height: $minHeight / 1.5;
  }

  @include break(xxl) {
    min-height: $minHeight;
  }
}
