$minHeight: 12rem;
$labelCololor: #9195BA;

.GridCard {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: ($gutter-h / 2) ($gutter-v / 2);
  font-family: $font-secondary;

  @include break(md) {
    flex-basis: 30%;
  }
}

.GridCard-image {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  min-height: $minHeight * 2;
  background-color: $color-background-3;
  background-size: cover;
  background-position: center top;

  @include break(md) {
    min-height: $minHeight * 1.5;
  }

  @include break(xxl) {
    min-height: $minHeight * 4;
  }
}

.GridCard-link {
  width: 100%;
}

.GridCard-type {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: $gutter-v / 4;
  color: $color-light;
  font-size: map-get($font-size-secondary, 3xs);
  font-weight: 300;
  background-color: $labelCololor;
  letter-spacing: $letter-spacing-1 / 2;
  text-transform: uppercase;

  span {
    margin: 0 ($gutter-h / 4);
  }
}

.GridCard-content {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: flex-start;
  width: 100%;
  padding: ($gutter-v / 2);
  background-color: $color-light;
}

.GridCard-date {
  color: $color-foreground-2;
  font-size: map-get($font-size-primary, xs);
}

.GridCard-title {
  margin: ($gutter-h / 4) 0;
  color: $color-foreground-1;
  font-weight: 500;
  text-transform: capitalize;
}

.GridCard-excerpt {
  color: $color-foreground-3;
  font-family: $font-primary;
  font-size: map-get($font-size-primary, xs);
  font-weight: 300;
  line-height: 1.5;
  text-transform: none;
}
