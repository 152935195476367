$athleteCardImageWidth: 12rem;

.AthleteCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 40rem;
  margin-bottom: $gutter-v;
  font-family: $font-secondary;
  border-radius: $border-radius-2;

  @include break(md) {
    flex-basis: 31.66%;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.AthleteCard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ($gutter-v * 1.5) $gutter-h;
  background-color: $color-light;
  border-radius: inherit;
  box-shadow: $box-shadow-low;
  transition: transform .2s, box-shadow .2s;

  @include break(md) {
    min-height: 10rem;
  }

  &:hover {
    box-shadow: $box-shadow-soft;
    transform: scale(1.015);
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
}

.AthleteCard-frame {
  width: 100%;
  max-width: $athleteCardImageWidth;
}

.AthleteCard-image {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 100%;
  margin-bottom: $gutter-v * 2;
  background-color: $color-background-2;
  background-size: cover;
  background-position: center top;
}

.AthleteCard-image--flag {
  padding-top: 75%;
  margin-bottom: $gutter-v*3;
  box-shadow: $box-shadow-even;
}

.AthleteCard-image--rounded {
  border-radius: $border-radius-circle;
}

.AthleteCard-image--shadowed {
  box-shadow: $box-shadow-low;
}

.AthleteCard-subHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $gutter-v*2;

  &--pilled {
    padding: $gutter-v/4 $gutter-h/1.67;
    background-color: $color-background-2;
    border-radius: $border-radius-2*10;
  }
}

.AthleteCard-flag {
  align-self: flex-end;
  min-width: 1.25rem;
  max-width: 1.5rem;
  margin-right: $gutter-h/3;
}

.AthleteCard-countryName {
  color: $color-foreground-2;
  font-family: $font-secondary;
  font-size: map-get($font-size-secondary, 3xs);
  line-height: 1;
  text-transform: capitalize;
}

.AthleteCard-title {
  margin-top: 0;
  margin-bottom: $gutter-v / 2.5;
  color: $color-foreground-1;
  font-weight: 500;
  font-size: map-get($font-size-secondary, sm);
  text-transform: capitalize;
}

.AthleteCard-ranking {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: capitalize;
}

.AthleteCard-rankingItem {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  &:first-child {
    margin-right: $gutter-h*1.25;
  }
}

.AthleteCard-stat {
  margin-left: $gutter-v / 2.5;
  color: $color-foreground-1;
  font-size: map-get($font-size-secondary, xs);
  font-weight: 600;
  line-height: 1;
}

.AthleteCard-label {
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, xxs);
  line-height: 1;

  &--capitalize {
    text-transform: capitalize;
  }
}

.AthleteCard-rank {
  position: relative;
  display: block;
  min-width: 1.5rem;
  margin-left: $gutter-h;
  text-align: center;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    opacity: .25;
  }

  &::before {
    left: -88%;
    background-image: url(cdn_image('/img/icon-wreath-left.svg'));
  }

  &::after {
    right: -88%;
    background-image: url(cdn_image('/img/icon-wreath-right.svg'));
  }
}

.AthleteCard-twitter {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 6rem;
  color: $color-foreground-3;
  font-size: map-get($font-size-secondary, xxs);
  background-color: $color-background-1;
  border-radius: 0 0 $border-radius-1 $border-radius-1;
  text-align: center;

  &-wrap {
    width: 100%;
    height: 100%;
    padding: $gutter-v $gutter-h;
  }

  &-icon {
    position: absolute;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: $gutter-h / 2;

    svg {
      fill: $color-foreground-3;
    }
  }
}
